import { apiCall } from "../services/api";
import { addError } from "./error";

export const getStockTurnover = (filters) => {
  const url = `${process.env.REACT_APP_API}/reports/stockturnover`;

  return apiCall("post", url,filters)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message);
    });
};
export const getLowStockProducts = (businessclients) => {
  const url = `${process.env.REACT_APP_API}/reports/lowstocks?businessclients=${businessclients}`;

  return apiCall("get", url)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message);
    });
};

export const stock_lead_report = (filter) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/reports/stockleadreport`;
    apiCall("post", url, filter)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err.message));
  });
};
// const loadData  =(payload)=>({
//     type:"LOAD_STOCKS_TURNOVER",
//     payload
// })
export const updateFilters = (newFilter) => ({
  type: "UPDATE_FILTER",
  payload: newFilter,
});

export const getNogoodsReport = (filters) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/reports/nogoods/?country=${filters.country}&businessclient=${filters.businessclient}&shipFrom=${filters.shipFrom}`;

    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message);
      });
  });
};

export const deliveryrateReport = (filters) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/reports/deliveryrate`;

    apiCall("post", url, filters)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message);
      });
  });
};
export const ordersWarehouse = () => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/reports/orderswarehouse`;

    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message);
      });
  });
};
export const noGoodsGeneral = () => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/reports/nogoodsgeneral`;

    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message);
      });
  });
};

// INVOICE

const createFilterUrl = (filters) =>
  filters
    ? Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";

export const getInvoices = (filters) => (dispatch) => {
  // dispatch(startLoading);
  // dispatch(removeError());
  const filtersUrl = createFilterUrl(filters);
  const url = `${process.env.REACT_APP_API}/reports/invoices?${filtersUrl}`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_INVOICES", payload: resp }))
    .catch((error) => {
      // dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateInvoiceFilters = (newFilter) => ({
  type: "UPDATE_INVOICE_FILTERS",
  payload: newFilter,
});
