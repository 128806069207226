import { apiCall } from "../services/api";
import { addError } from "./error";
import { Alert } from "rsuite";

import {
  GET_PRODUCTS,
  GET_CLIENT_PRODUCTS,
  GET_FILTERED_PRODUCTS,
  GET_PRODUCT_GROUP,
  GET_PRODUCT_GROUPS,
  UPDATE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  ADD_PRODUCTS_PRODUCT_GROUP,
  REMOVE_PRODUCTS_PRODUCT_GROUP,
  ADD_RELATED_PRODUCTS,
  REMOVE_RELATED_PRODUCTS,
} from "./actionTypes";

export const createProduct = (product) => {
  const url = `${process.env.REACT_APP_API}/products`;
  return new Promise((resolve, reject) => {
    apiCall("post", url, product)
      .then((product) => resolve(product))
      .catch((err) => reject(err.message));
  });
};

export const getProductList =
  ({ businessclient }) =>
  (dispatch) => {
    dispatch({
      type: "LOADING_START",
    });
    apiCall(
      `get`,
      `${process.env.REACT_APP_API}/products/get/list?businessclient=${businessclient}`
    )
      .then((products) => {
        dispatch({
          type: GET_PRODUCTS,
          products,
        });
        dispatch({
          type: "LOADING_STOP",
        });
      })
      .catch((err) => {
        dispatch({
          type: "LOADING_STOP",
        });

        dispatch(addError(err.message));
      });
  };

export const getFilteredProductList =
  ({ currentPage, itemsPerPage, searchTerm = "", selectedBclients = [] }) =>
  (dispatch) => {
    dispatch({
      type: "LOADING_START",
    });
    const bclientStr = selectedBclients.length
      ? selectedBclients
          .map((item) =>
            item._id ? `&businessclient=${item._id}` : `&businessclient=${item}`
          )
          .join("")
      : "";
    const searchTermStr = searchTerm ? `&searchTerm=${searchTerm}` : "";
    const url = `${process.env.REACT_APP_API}/products/?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}${searchTermStr}${bclientStr}`;
    apiCall(`get`, url)
      .then((products) => {
        dispatch({
          type: GET_FILTERED_PRODUCTS,
          products,
        });
        dispatch({
          type: "LOADING_STOP",
        });
      })
      .catch((err) => {
        dispatch(addError(err.message));
        dispatch({
          type: "LOADING_STOP",
        });
      });
    // .finally(dispatch({ type: "LOADING_STOP" }));
  };

export const getClientProductList = (businessclient) => (dispatch) => {
  apiCall(
    `post`,
    `${process.env.REACT_APP_API}/products/businessclient/${businessclient}`
  )
    .then((products) =>
      dispatch({
        type: GET_CLIENT_PRODUCTS,
        products,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

export const getProduct = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `${process.env.REACT_APP_API}/products/${id}`)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
};

export const deleteProduct = ({ id, history }) => {
  return apiCall("delete", `${process.env.REACT_APP_API}/products/${id}`)
    .then((resp) => {
      alert("Product Deleted");
      history.push("/products");
    })
    .catch((err) => Alert.error(err.message));
};

export const updateProduct = (id, body) => {
  return apiCall("put", `${process.env.REACT_APP_API}/products/${id}`, body)
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};

export const addPackingmaterial = ({ id, item, quantity }) => {
  return apiCall(
    "post",
    `${process.env.REACT_APP_API}/products/${id}/packmaterial`,
    { quantity, item }
  )
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};
export const deletePackingmaterial = ({ id, item }) => {
  return apiCall(
    "delete",
    `${process.env.REACT_APP_API}/products/${id}/packmaterial/${item}`
  )
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};

export const updateProductPackingMaterial = ({ id, item, quantity }) => {
  return apiCall(
    "put",
    `${process.env.REACT_APP_API}/products/${id}/packmaterial/${item}`,
    { quantity }
  )
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};

export const getMovements = ({ id, filters }) => {
  const url = `${process.env.REACT_APP_API}/products/${id}/movements`;
  return apiCall("post", url, filters)
    .then((resp) => resp)
    .catch((err) => err);
};

export const getStockBalance_per_inventory = (id) => {
  const url = `${process.env.REACT_APP_API}/products/${id}/inventories`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((err) => err);
};

export const uploadProdFromXLS = ({ products, businessclient }) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/products/upload/xls`;

    apiCall("post", url, {
      products,
      businessclient,
    })
      .then((resp) => resolve(resp))
      .catch((err) => reject(err.message));
  });
};

// export const pairToOrders = id => dispatch => {
//   const url = `${process.env.REACT_APP_API}/products/${id}/pairToOrders`;
//   apiCall("post", url)
//     .then(resp => alert(`Paired ${resp.paired} orders`))
//     .catch(err => dispatch(addError(err.message)));
// };

// //////////////////////////////////////////////////////////////////////////////
// Product Group
export const createProductGroup = (history, body) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/products/groups/`;
  apiCall(`post`, url, body)
    .then((resp) => {
      // dispatch({ type: CREATE_PRODUCT_GROUP, payload: resp });
      history.push(`/productgroup/${resp._id}`);
    })
    .catch((err) => dispatch(addError(err.message)));
};

export const getProductGroup = (_id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/products/groups/${_id}`;
  apiCall(`get`, url)
    .then((resp) =>
      dispatch({
        type: GET_PRODUCT_GROUP,
        payload: resp,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

export const getProductGroups = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/products/groups`;
  apiCall(`get`, url)
    .then((resp) =>
      dispatch({
        type: GET_PRODUCT_GROUPS,
        groups: resp,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

export const updateProductGroup =
  ({ id, name }) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API}/products/groups/${id}`;
    apiCall(`put`, url, {
      name,
    })
      .then((resp) =>
        dispatch({
          type: UPDATE_PRODUCT_GROUP,
          payload: resp,
        })
      )
      .catch((err) => dispatch(addError(err.message)));
  };

export const deleteProductGroup = (id, history) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/products/groups/${id}`;
  apiCall(`delete`, url)
    .then((resp) =>
      dispatch({
        type: DELETE_PRODUCT_GROUP,
        payload: resp,
      })
    )
    .then(() => history && history.push("/products/list/group"))
    .catch((err) => dispatch(addError(err.message)));
};

export const addProductsProductGroup =
  ({ _id, products }) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API}/products/groups/${_id}/products`;
    apiCall(`post`, url, {
      products,
    })
      .then((resp) =>
        dispatch({
          type: ADD_PRODUCTS_PRODUCT_GROUP,
          payload: resp,
        })
      )
      .catch((err) => dispatch(addError(err.message)));
  };

export const removeProductsProductGroup =
  ({ _id, products }) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API}/products/groups/${_id}/products/${products[0]}`;
    apiCall(`delete`, url)
      .then((resp) =>
        dispatch({
          type: REMOVE_PRODUCTS_PRODUCT_GROUP,
          payload: resp,
        })
      )
      .catch((err) => dispatch(addError(err.message)));
  };

//////////////////////////////////////////////////////////////////////////////
// quotations
export const uploadQuotations = (query) => {
  const url = `${process.env.REACT_APP_API}/products/quotations/uploadxls`;
  return apiCall("post", url, query)
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};
export const getQuotations = (productId) => {
  const url = `${process.env.REACT_APP_API}/products/${productId}/quotations`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};

export const deleteProducQuotation = ({ id = null, qid }) => {
  const url = `${process.env.REACT_APP_API}/products/${id}/quotations/${qid}`;
  return apiCall("delete", url)
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};

//REALTED PRODUCTS
export const addRelatedProducts =
  ({ id, products }) =>
  (dispatch) => {
    dispatch({
      type: "PRODUCT_IS_LOADING",
    });
    const url = `${process.env.REACT_APP_API}/products/${id}/related`;
    apiCall("post", url, {
      products,
    })
      .then((resp) =>
        dispatch({
          type: ADD_RELATED_PRODUCTS,
          product: resp,
        })
      )
      .catch((err) => dispatch(addError(err.message)));
  };

export const removeRelatedProducts =
  ({ id, products }) =>
  (dispatch) => {
    dispatch({
      type: "PRODUCT_IS_LOADING",
    });
    const url = `${process.env.REACT_APP_API}/products/${id}/related`;
    apiCall("put", url, {
      products,
    })
      .then((resp) =>
        dispatch({
          type: REMOVE_RELATED_PRODUCTS,
          product: resp,
        })
      )
      .catch((err) => dispatch(addError(err.message)));
  };

// //////////////////////////////////////////////////////////////////////////////
// Related product Groups
export const createRelatedProductGroup = (history, body) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/relatedproducts/`;
  apiCall(`post`, url, body)
    .then((resp) => {
      // dispatch({ type: CREATE_PRODUCT_GROUP, payload: resp });
      history.push(`/productgroup/${resp._id}`);
    })
    .catch((err) => dispatch(addError(err.message)));
};

export const getRelatedProductGroup = (_id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/relatedproducts/${_id}`;
  apiCall(`get`, url)
    .then((resp) =>
      dispatch({
        type: GET_PRODUCT_GROUP,
        payload: resp,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

export const getRelatedProductGroups = (params) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/relatedproducts?businessclient=${params.businessclient}&product=${params.product}`;
  apiCall(`get`, url)
    .then((resp) =>
      dispatch({
        type: GET_PRODUCT_GROUPS,
        groups: resp,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

export const updateRelatedProductGroup =
  ({ id, name }) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API}/relatedproducts/${id}`;
    apiCall(`put`, url, {
      name,
    })
      .then((resp) =>
        dispatch({
          type: UPDATE_PRODUCT_GROUP,
          payload: resp,
        })
      )
      .catch((err) => dispatch(addError(err.message)));
  };

export const deleteRelatedProducGroup = (id, history) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/relatedproducts/${id}`;
  apiCall(`delete`, url)
    .then((resp) =>
      dispatch({
        type: DELETE_PRODUCT_GROUP,
        payload: resp,
      })
    )
    .then(() => history && history.push("/relatedproducts"))
    .catch((err) => dispatch(addError(err.message)));
};

export const addProductsRelatedProduct =
  ({ _id, products }) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API}/relatedproducts/${_id}/products`;
    apiCall(`post`, url, {
      products,
    })
      .then((resp) =>
        dispatch({
          type: ADD_PRODUCTS_PRODUCT_GROUP,
          payload: resp,
        })
      )
      .catch((err) => dispatch(addError(err.message)));
  };

export const removeProductsRelatedProduct =
  ({ _id, products }) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API}/relatedproducts/${_id}/products/${products[0]}`;
    apiCall(`delete`, url)
      .then((resp) =>
        dispatch({
          type: REMOVE_PRODUCTS_PRODUCT_GROUP,
          payload: resp,
        })
      )
      .catch((err) => dispatch(addError(err.message)));
  };

// with stock balances
export const getProductListLimit = (filters) => {
  const filtersUrl = filters
    ? "/?" +
      Object.entries(filters)
        .filter(
          ([k, value]) => value || (Array.isArray(value) && value.length > 0)
        )
        .map(([key, value]) =>
          Array.isArray(value)
            ? value.map((v) => key + "=" + v).join("&")
            : key + "=" + value
        )
        .join("&")
    : "";

  const url = `${process.env.REACT_APP_API}/products${filtersUrl}`;
  return new Promise((resolve, reject) => {
    apiCall(`get`, url)
      .then((products) => resolve(products))
      .catch((err) => {
        reject(err);
      });
  });
};

//without stock balacnes
export const getProductListV2 = (filters) => {
  const filtersUrl = filters
    ? "/?" +
      Object.entries(filters)
        .filter(
          ([k, value]) => value || (Array.isArray(value) && value.length > 0)
        )
        .map(([key, value]) =>
          Array.isArray(value)
            ? value.map((v) => key + "=" + v).join("&")
            : key + "=" + value
        )
        .join("&")
    : "";

  const url = `${process.env.REACT_APP_API}/products/get/list${filtersUrl}`;
  return new Promise((resolve, reject) => {
    apiCall(`get`, url)
      .then((products) => resolve(products))
      .catch((err) => {
        reject(err);
      });
  });
};
