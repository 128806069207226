import React, { useState } from "react";
import { useUpdateContext } from "../../../context/UpdateContext";
import { Header, Segment, Button, Divider } from "semantic-ui-react";
import Stocksturnover from "../Stockturnover";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";

function Lowstockperwarehouse({ setBusinessclients, isLoading }) {
  const { setLowStockWarehouseView } = useUpdateContext();

  const [clickedIndex, setIndex] = useState(100);
  let total = 0;

  const message_view = (
    <div className="flex--left">
      {localStorage.getItem("lowstock") &&
        JSON.parse(localStorage.getItem("lowstock")).map((item, index) => {
          total = total + item.products.length;
          return (
            <Button
              style={{ margin: "5px" }}
              active={index === clickedIndex ? true : false}
              basic
              onClick={() => {
                setLowStockWarehouseView([item]);
                setIndex(index);
              }}
            >
              <div className="flex--column">
                <Header as="h5">{item.warehouse.name}</Header>
                <div>{` ${item.businessclient.name} - ${item.products.length}`}</div>
              </div>
            </Button>
          );
        })}
    </div>
  );
  return (
    <div>
      <Segment
        loading={isLoading}
        // warning
        style={{
          minWidth: "40%",
          // maxWidth: "1000px",
          maxWidth: "95%",
          // marginBottom: "10px",
          // marginRight: "10px",
        }}
      >
        <div className="flex--between">
          <div className="flex--left">
            <Header>Warehouse Products View</Header>
            <BusinessclientFilter
              style={{ marginLeft: "20px"}}
              multiple={true}
              useFilter={false}
              handleChange={(value) => {
                // console.log('value:',value)
                setBusinessclients(value);
              }}
            ></BusinessclientFilter>
          </div>

          <Button
            basic
            size="tiny"
            style={{ maxHeight: "30px" }}
            onClick={() =>
              setLowStockWarehouseView(
                JSON.parse(localStorage.getItem("lowstock"))
              )
            }
          >
            View All
          </Button>
        </div>
        <Divider></Divider>

        {message_view}
      </Segment>

      <Divider></Divider>

      <Stocksturnover showLowStocks={true}></Stocksturnover>
    </div>
  );
}

export default Lowstockperwarehouse;
