import React from "react";
import { Divider, Input, Icon, Button, Message, Tab } from "semantic-ui-react";

import { Alert } from "rsuite";
import { apiCall } from "../../services/api";
import CanViewPage from "../../role-based-access/CanViewPage";
import CourierFilter from "../Filters/CourierFilter";

class ReturnedOrders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      courier: null,
      successSound: null,
      failSound: null,
      id: "",
      isLoading: false,
      status: null,
      submitError: null,
      // errors: null,
      manualModeOn: false,
      isUpdated: false,
      isSubmited: false,
      order: { items: [], businessclient: { name: "" } },
    };

    this.input = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitWithEvent = this.handleSubmitWithEvent.bind(this);
    this.handleManualSubmit = this.handleManualSubmit.bind(this);
    this.setScanMode = this.setScanMode.bind(this);
    this.setManualMode = this.setManualMode.bind(this);
    this.playSound = this.playSound.bind(this);
  }

  handleChange({ value, scannedType, courier }) {
    if (
      this.state.id.length === 1 &&
      // !this.state.isLoading &&
      // !this.state.status &&
      !this.state.isUpdated &&
      !this.state.manualModeOn
    ) {
      // this.state.isUpdated=true
      // setTimeout(this.handleSubmit, 1000);
    }

    let newVal = value.replace("=", "_");

    if (newVal.substring(0, 1) === "_") newVal = newVal.substring(1);

    this.setState({ id: newVal });

    //if scanner not pressing enter , check if submit is neccessary
    setTimeout(() => {
      if (!this.state.isSubmited && this.state.id && !this.state.manualModeOn) {
        this.handleSubmit({ scannedType, courier });
        // alert('submitting..')
      }
    }, 1500);
  }

  handleSubmit({ scannedType, courier }) {
    // console.log("submit");
    this.setState({ isSubmited: true });
    const url = `${process.env.REACT_APP_API}/packer/orders/set/notshipped?id=${this.state.id}&scannedType=${scannedType}&courier=${this.state.courier}`;
    this.setState({ isLoading: true });

    apiCall("get", url)
      .then((resp) => {
        Alert.success("Order Registered.", 2000);
        this.setState((prevState) => ({
          isSubmited: false,
          isUpdated: false,
          isLoading: false,
          status: resp.status === "success" ? "success" : "fail",
          id: resp.status === "success" ? "" : prevState.id,
          order: resp.order,
        }));

        if (resp.status === "success") this.playSound(this.state.successSound
          
        );
      })
      .catch((err) => {
        this.playSound(this.state.failSound);
        this.setState({
          isSubmited: false,
          submitError: err.message,
          isLoading: false,
          status: "fail",
        });
      });
  }

  handleSubmitWithEvent(e, { scannedType, courier }) {
    e.preventDefault();
    // this.handleSubmit();
    if (!this.state.isSubmited && this.state.id && !this.state.manualModeOn) {
      this.handleSubmit({ scannedType, courier });
      // alert('submitting..')
    }
  }

  handleManualSubmit(e, { scannedType }) {
    e.preventDefault();
    if (!this.state.isSubmited && this.state.id && this.state.manualModeOn) {
      this.handleSubmit({ scannedType });
    }
  }

  setManualMode() {
    this.setState({ manualModeOn: !this.state.manualModeOn, status: null });
  }

  setScanMode() {
    this.setState({ manualModeOn: false, id: "", status: null });
  }

  playSound(audio) {
    // console.dir(audio.ended);
    // console.dir(audio.currentTime);
    if (!audio.ended) {
      audio.currentTime = 0;
    }
    audio.play();
  }

  componentDidMount() {
    this.input.current.focus();
    this.setState({
      successSound: new Audio("/positive-1.mp3"),
      failSound: new Audio("/negative-1.mp3"),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.id === "") this.input.current.focus();
    if (prevState.id === "" && this.state.id.length > 0)
      this.setState({ status: null, submitErrors: null });
  }

  render() {
    const { id, isLoading, status, submitError } = this.state;

    const panes = [
      {
        menuItem: "SCAN Internal ID",
        render: () => (
          <main>
            <section className="a-wrapper">
              <form
                style={{
                  maxWidth: "350px",
                  transform: "scale(2)",
                  transformOrigin: "0% 0% 0px",
                }}
                onSubmit={(e) =>
                  this.handleSubmitWithEvent(e, { scannedType: "internalId" })
                }
              >
                <label>Order Internal ID</label>
                <div
                  className="flex--between"
                  style={{ marginBottom: "1.5em" }}
                >
                  <div
                    style={{
                      width: "100%",
                      marginRight: "0.2em",
                    }}
                  >
                    <Input
                      ref={this.input}
                      loading={this.state.isLoading}
                      fluid
                      focus
                      placeholder="Scan the barcode..."
                      value={id}
                      onChange={(e, { value }) =>
                        this.handleChange({ value, scannedType: "internalId" })
                      }
                    />
                  </div>
                  {/* Clear Inut | Manual Mode */}
                  {this.state.manualModeOn && (
                    <Button
                      icon="delete"
                      onClick={() => this.setState({ id: "" })}
                    >
                      Clear
                    </Button>
                  )}
                </div>

                {/* On Fail Options */}
                {this.state.status === "fail" && (
                  <>
                    <Button color="blue" onClick={this.setScanMode}>
                      Scan again
                    </Button>
                    {/* <Button color="orange" onClick={this.setManualMode}>
                      <Icon name="hand point down outline" />
                      Manual edit
                    </Button> */}
                  </>
                )}

                <Button
                  color={!this.state.manualModeOn ? "orange" : "red"}
                  onClick={this.setManualMode}
                >
                  <Icon name="hand point down outline" />
                  {!this.state.manualModeOn ? "Enter Manual" : "Exit Manual"}
                </Button>
                {/* Manual Submit */}
                {this.state.manualModeOn && this.state.status !== "fail" && (
                  <Button
                    type="submit"
                    loading={isLoading}
                    disabled={!id}
                    color={
                      (!status && "blue") ||
                      (status === "success" && "green") ||
                      (status === "fail" && "orange")
                    }
                    onClick={(e) =>
                      this.handleManualSubmit(e, { scannedType: "internalId" })
                    }
                  >
                    Register Order
                  </Button>
                )}

                {/* Status Message */}
                {status === "success" && (
                  <Message
                    success
                    header="Items"
                    content={this.state.order.items.map((item) => (
                      <div>
                        {item.name} - {item.quantity}
                      </div>
                    ))}
                  />
                )}
                {status === "fail" && (
                  <Message
                    warning
                    header="Order not registred!"
                    content={submitError}
                  />
                )}
              </form>
            </section>
          </main>
        ),
      },
      {
        menuItem: "SCAN AWB (Courier TRACKING ID)",
        render: () => (
          <main>
            <section className="a-wrapper">
              <CourierFilter
                useFilter={false}
                multiple={false}
                value={this.state.courier}
                handleChange={(value) => {
                  // console.log("courier:", value);
                  this.setState({ ...this.state, courier: value });
                }}
              ></CourierFilter>
              <form
                style={{
                  maxWidth: "350px",
                  transform: "scale(2)",
                  transformOrigin: "0% 0% 0px",
                }}
                onClick={(e) => {
                  this.handleSubmitWithEvent(e, {
                    scannedType: "AWB",
                    courier: this.state.courier,
                  });
                }}
              >
                <label>Tracking AWB</label>
                <div
                  className="flex--between"
                  style={{ marginBottom: "1.5em" }}
                >
                  <div
                    style={{
                      width: "100%",
                      marginRight: "0.2em",
                    }}
                  >
                    <Input
                      ref={this.input}
                      loading={this.state.isLoading}
                      fluid
                      focus
                      placeholder="Scan the barcode..."
                      value={id}
                      onChange={(e, { value }) =>{
                        console.log('value:',value)
                      if(value)  this.handleChange({
                          value,
                          scannedType: "AWB",
                          courier: this.state.courier,
                        });
                      }
                        
                      }
                    />
                  </div>
                  {/* Clear Inut | Manual Mode */}
                  {this.state.manualModeOn && (
                    <Button
                      icon="delete"
                      onClick={() => this.setState({ ...this.state, id: "" })}
                    >
                      Clear
                    </Button>
                  )}
                </div>

                {/* On Fail Options */}
                {this.state.status === "fail" && (
                  <>
                    <Button color="blue" onClick={this.setScanMode}>
                      Scan again
                    </Button>
                    {/* <Button color="orange" onClick={this.setManualMode}>
                      <Icon name="hand point down outline" />
                      Manual edit
                    </Button> */}
                  </>
                )}

                <Button
                  color={!this.state.manualModeOn ? "orange" : "red"}
                  onClick={this.setManualMode}
                >
                  <Icon name="hand point down outline" />
                  {!this.state.manualModeOn ? "Enter Manual" : "Exit Manual"}
                </Button>
                {/* Manual Submit */}
                {this.state.manualModeOn && this.state.status !== "fail" && (
                  <Button
                    type="submit"
                    loading={isLoading}
                    disabled={!id}
                    color={
                      (!status && "blue") ||
                      (status === "success" && "green") ||
                      (status === "fail" && "orange")
                    }
                    onClick={(e) =>
                      this.handleManualSubmit(e, { scannedType: "AWB" })
                    }
                  >
                    Register Order
                  </Button>
                )}

                {/* Status Message */}
                {status === "success" && (
                  <Message
                    success
                    header="Items"
                    content={this.state.order.items.map((item) => (
                      <div>
                        {item.name} - {item.quantity}
                      </div>
                    ))}
                  />
                )}
                {status === "fail" && (
                  <Message
                    warning
                    header="Order not registred!"
                    content={submitError}
                  />
                )}
              </form>
            </section>
          </main>
        ),
      },
    ];
    return (
      <CanViewPage path="/packer/returns">
        <>
          <header>
            <div
              className="a-wrapper"
              style={{
                marginTop: "1.2em",
                marginBottom: "2em",
              }}
            >
              {/* <Button onClick={() => this.playSound(this.state.successSound)}>Play Sound</Button> */}
            </div>
            <Divider />
          </header>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            // activeIndex={activeIndex}
            // onTabChange={handleTabChange}
          />
        </>
      </CanViewPage>
    );
  }
}

export default ReturnedOrders;
