import React from "react";
import { Button, Select,Icon } from "semantic-ui-react";

function ActionsSecondary({ handleOrderAction }) {
  const [rejectClicked, setRejectClicked] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState(null);

  return (
    <div className="actions__secondary-btns vertical--top">
      <Button  color="olive" onClick={() => handleOrderAction("orderready")}>
        <Icon name="list layout" />
        Back to Ready
      </Button>
      <Button  color="yellow" onClick={() => handleOrderAction("draft")}>
        <Icon name="list layout" />
        Draft
      </Button>
      <Button  color="orange" onClick={() => handleOrderAction("nogoods")}>
        <Icon name="list layout" />
        No goods
      </Button>
      <div
        className={`reject-btn__wrapper ${
          rejectClicked ? "" : "reject-btn__wrapper_closed"
        }`}
      >
        {/* <Button basic color="red" onClick={() => setRejectClicked(true)}> */}
          {/* <Icon name="delete" /> */}
          {/* Reject */}
        {/* </Button> */}

        {rejectClicked && (
          <>
            <div style={{ display: "inline-block", marginRight: "0.4em" }}>
              <Select
                placeholder="Reject Reason"
                onChange={(e, { value }) => {
                  setRejectReason(value);
                }}
                options={[
                  "No products",
                  "Damaged goods",
                  "No wrap materials",
                  "Reason 4",
                  "Other reason"
                ].map((each, id) => ({
                  key: id,
                  value: each,
                  text: each
                }))}
              ></Select>
            </div>
            <Button.Group>
              <Button
                disabled={!rejectReason}
                color="grey"
                onClick={() => {
                  setRejectClicked(false);
                  setRejectReason(null);
                  handleOrderAction("reject", rejectReason);
                }}
              >
                Confirm rejection
              </Button>
              <Button
                onClick={() => {
                  setRejectClicked(false);
                  setRejectReason(null);
                }}
              >
                Cancel
              </Button>
            </Button.Group>
          </>
        )}
      </div>
    
    </div>
  );
}
export default ActionsSecondary;
