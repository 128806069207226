import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Segment,
  Input,
  Table,
  Button,
  Divider,
  PlaceholderImage,
  Placeholder,
} from "semantic-ui-react";
import { createTemplate, getTemplate } from "../../actions/courier_cod_teamplates";
import { Alert } from "rsuite";

import CourierFilter from "../Filters/CourierFilter";

const look_up_key_options = [
  { text: "Internal Id", value: "internalNumericId" },
  { text: "Tracking Id", value: "shipping.trackingId" },
  { text: "Client Order Number", value: "clientOrderNr" },
];

const Newteamplate = ({ template, setTemplate }) => {
  const [courier, setCourier] = useState();
  const [templates, setTemplates] = useState([]);
  const [clickedIndex, setIndex] = useState(0);
  const [addNew, setAddNew] = useState(false);

  const handleCreateTemplate = () => {
    setAddNew(false);
    createTemplate({ ...template, courier })
      .then((resp) => setTemplate(resp))
      .catch((err) => Alert.error(err));
  };

  useEffect(() => {
    //Load templates for this courier
    if (courier)
      getTemplate({ courier }).then((resp) => {
        setTemplates(resp);
        if (resp.length > 0) setTemplate(resp[0]);
      });
    if (courier === "") {
      setTemplates([]);
      setAddNew(false);
    }
  }, [courier]);

  const teamplateComponent = templates.map((item, index) => (
    <Button
      style={{ margin: "5px" }}
      active={index === clickedIndex ? true : false}
      basic
      onClick={() => {
        setTemplate(item);
        setIndex(index);
      }}
    >
      <Table>
        <tr>
          <td>Look up key</td>
          <td>{item.look_up_key}</td>
        </tr>
        <tr>
          <td>Look up column</td>
          <td>{item.look_up_key_cln}</td>
        </tr>
        <tr>
          <td>Amount column</td>
          <td>{item.amount_cln}</td>
        </tr>
      </Table>
    </Button>
  ));

  const newTemplateForm = (
    <div>
      <Table
        style={{
          width: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {" "}
        <tr>
          <Table.Cell disabled={!courier}>Title Row</Table.Cell>
          <Table.Cell disabled={!courier}>
            <Input
              min={1}
              disabled={!courier}
              defaultValue={1}
              onChange={(e, { value }) => {
                if (value > 0) setTemplate({ ...template, title_row: value });
              }}
            ></Input>
          </Table.Cell>
        </tr>
        <tr>
          <Table.Cell disabled={!courier}>Look up key</Table.Cell>
          <Table.Cell disabled={!courier}>
            {" "}
            <Dropdown
              disabled={!courier}
              placeholder="State"
              search
              selection
              defaultValue={`shipping.trackingId`}
              options={look_up_key_options}
              onChange ={(e,{value})=>setTemplate({ ...template, look_up_key: value })}
            ></Dropdown>
          </Table.Cell>
        </tr>
        <tr>
          <Table.Cell disabled={!courier}>Key Column</Table.Cell>
          <Table.Cell disabled={!courier}>
            {" "}
            <Input
              disabled={!courier}
              defaultValue={1}
              onChange={(e, { value }) => {
                if (value > 0)
                  setTemplate({ ...template, look_up_key_cln: value });
              }}
            ></Input>
          </Table.Cell>
        </tr>
        {/* <tr>
                <Table.Cell disabled={!courier}>Data Start Row</Table.Cell>
                <Table.Cell>
                  {" "}
                  <Input
                    disabled={!courier}
                    defaultValue={1}
                    onChange={(e, { value }) => {
                      if (value > 0)
                        setTemplate({ ...template, dataStartRow: value });
                    }}
                  ></Input>
                </Table.Cell>
              </tr> */}
        <tr>
          <Table.Cell disabled={!courier}>Amount Column</Table.Cell>
          <Table.Cell>
            {" "}
            <Input
              disabled={!courier}
              defaultValue={1}
              onChange={(e, { value }) => {
                if (value > 0) setTemplate({ ...template, amount_cln: value });
              }}
            ></Input>
          </Table.Cell>
        </tr>
      </Table>
      <Button
        color="green"
        style={{ width: "500px" }}
        onClick={() => handleCreateTemplate()}
      >
        Create Template
      </Button>
    </div>
  );

  const PlaceholderExampleImage = () => (
    <Placeholder style={{ height: 150, width: 150 }}>
      <PlaceholderImage />
    </Placeholder>
  );

  return (
    <Segment>

      <CourierFilter
        style={{ width: "500px" }}
        multiple={false}
        useFilter={false}
        handleChange={(value) => setCourier(value)}
        value={courier}
      ></CourierFilter>

      {templates.length > 0 && (
        <Button style={{ marginLeft: "20px" }} onClick={() => setAddNew(true)}>
          Add New
        </Button>
      )}
      {courier && <Divider></Divider>}

      {!addNew && teamplateComponent}
      {/* {templates.length === 0&& (
        <div
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        >
          <PlaceholderExampleImage></PlaceholderExampleImage>
        </div>
      )} */}

      {addNew && newTemplateForm}

      {templates.length === 0 && courier && (
        <div>
          <p>No template found. Create new teamplate.</p>
          {newTemplateForm}
        </div>
      )}
    </Segment>
  );
};

export default Newteamplate;
