import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import "../Dropzone/dropzone.scss";
import { uploadDocuments } from "../../actions/files";
import { Alert } from "rsuite";
// import businessclients from '../../reducers/businessclients';
import { Button } from "semantic-ui-react";

const Fileupload = ({
  category,
  businessclient,
  trigger,
  triggerId,
  accept = ".pdf,.xlsx,.xls,.dot",
  setIsloading,
  scope = "print",
  description = "no description",
  getUploadedFiles,
  filetype = "document",
  country,
  print = false,
}) => {
  const [files, setFiles] = useState([]);
  const [data, setData] = useState();

  const previewFiles = files.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const uploadFiles = () => {
    // this.props.uploadExecuted();

    if (!trigger || !triggerId) {
      Alert.error(
        "Missing mandatory data for upload (businessclient,trigger,triggerId)"
      );
      return;
    }
    const data = new FormData();

    data.append(
      `path`,
      `${category}/${businessclient}/${trigger}/${triggerId}`
    );
    data.append(`description`, description);
    data.append(`trigger`, trigger);
    data.append(`triggerId`, triggerId);
    data.append(`businessclient`, businessclient);
    data.append(`filetype`, filetype);
    data.append(`scope`, scope);
    data.append(`print`, print);
    if (country) data.append(`country`, country);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.append(`file`, file);
    }
    // console.log('data:',data)

    setData(data);
  };

  useEffect(() => {
    if (data)
      uploadDocuments({files: data })
        .then((resp) => {
          Alert.success("Files Uploaded");
          // console.log('from files:',resp)
          getUploadedFiles(resp.uploadedFiles);
          setFiles([]);
        })
        .catch((err) => Alert.error(JSON.stringify(err)));
  }, [data, setIsloading]);

  return (
    <div>
      <Dropzone onDrop={(files) => setFiles(files)} accept={accept}>
        {({ getRootProps, getInputProps }) => (
          <section className="drop_container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {previewFiles.length > 0 && (
              <aside>
                <h4>Selected Files</h4>
                {previewFiles}
              </aside>
            )}
          </section>
        )}
      </Dropzone>
      <div className="flex_alignRight" style={{ marginTop: "10px" }}>
        <Button
          // onClick={() => console.log('uploading')}
          onClick={() => uploadFiles()}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

export default Fileupload;
