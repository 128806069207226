import React, { useState, useEffect } from "react";
import { Dropdown, Input, Divider, Button } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import { apiCall } from "../../../../services/api";
import { connect } from "react-redux";

import { setDropList } from "../../../../actions/filtersmain";

const EcontValidator = ({
  country,
  oldPostcode,
  oldCity,
  oldProvince,
  oldStreet,
  oldStreetNr,
  selectedAddress,
  isEcontValidator,
  handleToggleModal,
  setDropList,
  cities,
}) => {
  //   const [cities, setCities] = useState([]);
  const [newPostcode, setPostCode] = useState();
  const [newCity, setNewCity] = useState();
  const [newProvice, setNewProvice] = useState();
  const [newStreet, setNewStreet] = useState();
  const [streets, setStreets] = useState([]);
  const [newStreetNr, setNewStreetNr] = useState("");

  const getCity = (post) => {
    for (let i = 0; i < cities.length; i++) {
      if (post === cities[i].postCode) {
        setNewCity(cities[i].name);
        setNewProvice(cities[i].regionName);
        return {
          name: cities[i].name,
          id: cities[i].id,
          regionName: cities[i].regionName,
        };
      }
    }

    return false;
  };

  const replaceData = () => {
    selectedAddress({
      newPostcode,
      newCity,
      newProvice,
      newStreet,
      newStreetNr,
    });
    handleToggleModal();
  };

  useEffect(() => {
    if (oldPostcode) {
      setPostCode(oldPostcode.replace(/ /g, ""));
      //find with this post code
    }
  }, [oldPostcode, oldCity, oldStreet]);

  useEffect(() => {
    //findCity
    const city = getCity(newPostcode);

    // console.log(city);
    //getStreets
    if (city.id) {
      const url = `${process.env.REACT_APP_API}/orders/econt/streets`;

      apiCall("post", url, { cityID: city.id }).then((resp) =>
        setStreets(resp.streets).catch((error) => alert("connection error"))
      );
    }
  }, [cities, newPostcode]);

  useEffect(() => {
    if (cities.length === 0) {
      const url = `${process.env.REACT_APP_API}/orders/econt/cities`;
      let countryCode = "BG";

      if (country === "Greece") countryCode = "GR";

      apiCall("post", url, { countryCode })
        .then((resp) => {
          setDropList({ name: "bgcities", list: resp.cities });
        })
        .catch((error) => alert("connection error"));
    }
  }, []);

  if (cities.length === 0) return <div>Getting cities...</div>;

  return (
    <Modal
      open={isEcontValidator}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={handleToggleModal}
      center
      classNames={{
        modal: "",
      }}
      styles={{
        width: "900px",
      }}
    >
      <div>
        <segment name="originalvalues">
          <h4>Original</h4>
          <div>
            post:<span style={{ fontWeight: "bold" }}>{oldPostcode}</span>
          </div>
          <div>
            city:
            <span style={{ fontWeight: "bold" }}>{oldCity}</span>
          </div>
          <div>
            province:
            <span style={{ fontWeight: "bold" }}> {oldProvince}</span>
          </div>
          <div>
            street:
            <span style={{ fontWeight: "bold" }}> {oldStreet}</span>
          </div>
          <div>
            streetNr:
            <span style={{ fontWeight: "bold" }}>{oldStreetNr}</span>
          </div>
        </segment>
        <Divider></Divider>
        <segment name="replacevalues">
          <h4>Replace with</h4>

          <div>
            post:
            <Dropdown
              loading={cities.length === 0}
              name="newpostcode"
              clearable={true}
              selection={true}
              fluid
              selectOnBlur={true}
              closeOnChange={true}
              style={{ minWidth: "500px" }}
              search
              value={newPostcode}
              options={cities.map((item) => ({
                key: item.id,
                value: item.postCode,
                text: `${item.postCode}-${item.name}`,
              }))}
              onChange={(e, { value, name }) => {
                setPostCode(value);
              }}
            ></Dropdown>
          </div>
          <div>
            New City:
            <span style={{ fontWeight: "bold" }}>{newCity}</span>
          </div>
          <div>
            New Province:
            <span style={{ fontWeight: "bold" }}>{newProvice} </span>
          </div>
          <div>
            streets:
            <Dropdown
              loading={streets.length === 0}
              name="newStreet"
              clearable={true}
              selection={true}
              fluid
              selectOnBlur={true}
              closeOnChange={true}
              style={{ minWidth: "500px" }}
              search
              value={newStreet}
              options={streets.map((item) => ({
                key: item.id,
                value: item.name,
                // text: item.nameEn,
                text: item.name,
              }))}
              onChange={(e, { value, name }) => {
                setNewStreet(value);
              }}
            ></Dropdown>
          </div>
          <div>
            streetNr:
            <Input
              value={newStreetNr}
              onChange={(e, { value, name }) => {
                setNewStreetNr(value);
              }}
            ></Input>
          </div>
        </segment>
        <segment name="buttons" className="flex--between">
          <div name="left"></div>
          <div name="right">
            {/* <Button onClick={() => handleToggleModal()}>Cancel</Button> */}
            <Button onClick={() => replaceData()}>Update</Button>
          </div>
        </segment>
      </div>
    </Modal>
  );
};

function mapStateToProps(reduxState) {
  return {
    cities: reduxState.droplists.bgcities,
  };
}
export default connect(mapStateToProps, { setDropList })(EcontValidator);
