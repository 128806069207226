import React, { useEffect, useState } from "react";
import { Header, Segment, Button, Pagination } from "semantic-ui-react";

import {
  getServiceQuotations,
  createServiceQuotation,
  deleteServiceQuotation,
  updateServiceQuotation,
} from "../../actions/servicequotation";
// import CanViewPage from "../../role-based-access/CanViewPage";
// import ExportButton from "../../ExportButton";
import ItemsPerPage from "../ItemsPerPage";
import ServicesQuotationTable from "./ServicesQuotationTable";
import Filters from "./filters";
import Quotation from "./quotation";
// import Confirm from "./Confirm";
import "./services.scss";
import { useUpdateContext } from "../../context/UpdateContext";

import Copyquoatations from "./copyquotations";
import { Alert } from "rsuite";

function Quotations({history}) {
  const { businessclient, country,courier } = useUpdateContext();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [serviceType, setServicetype] = useState("");
  const [onlyStandard, setOnlyStandard] = useState(false);
  const [onlyActive, setOnlyActive] = useState(false);
  const [showCopymodal, setShowcopymodal] = useState(false);

  const [serviceQuotations, setList] = useState([]);
  const [total,setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false);
  const [refresh,setRefresh] =useState(false)
  
  useEffect(() => {
    setLoading(true);
    if (onlyStandard)
      getServiceQuotations({
        courier,
        itemsPerPage,
        currentPage,
        businessclient: "standard",
        country,
        serviceType,
        isActive: onlyActive,
      })
        .then((resp) => {
          setLoading(false);
          setList(resp.foundQuotations);
          setTotal(resp.total)
        })
        .catch((err) => Alert.error(err));
    else
      getServiceQuotations({
        courier,
        itemsPerPage,
        currentPage,
        businessclient,
        country,
        serviceType,
        isActive: onlyActive ? onlyActive : "",
      })
        .then((resp) => {
          setLoading(false);
          setList(resp.foundQuotations);
          setTotal(resp.total)

        })
        .catch((err) => Alert.error(err));
  }, [
    onlyStandard,
    itemsPerPage,
    currentPage,
    businessclient,
    country,
    serviceType,
    onlyActive,
    courier,
    refresh
  ]);



  const handlePageChange = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  const handleToggle = ({ id, isActive }) => {
    setLoading(true)
 updateServiceQuotation({ id, query: { isActive } }).then(resp=>setRefresh(!refresh))
  }

 const handleCreate=(props)=>{
  setLoading(true)
  createServiceQuotation(props).then(resp=>setRefresh(!refresh)).catch(err=>Alert.error(err))
 }

 const handleDelete=(id)=>{
  setLoading(true)
  deleteServiceQuotation(id).then(resp=>setRefresh(!refresh)).catch(err=>Alert.error(err))
 }


  return (
    // <CanViewPage path="/services">
    <main className="page-wrapper">
      {/* <Confirm
        isOpen={isOpen}
        showWarning={showWarning}
        handleConfirmation={handleConfirmation}
        id={id}
      ></Confirm> */}

      <Copyquoatations
        showCopymodal={showCopymodal}
        setShowcopymodal={setShowcopymodal}
      ></Copyquoatations>

      <div class="flex--between">
        <Header>Quotations</Header>
        <div>
          <Quotation createServiceQuotation={handleCreate} />

          <Button onClick={() => history.push("/quotations/import")}>
            Import
          </Button>
          <Button onClick={() => setShowcopymodal(true)}>CopyQuotations</Button>
        </div>
      </div>

      <Filters
        serviceType={serviceType}
        setServicetype={setServicetype}
        onlyStandard={onlyStandard}
        setOnlyStandard={setOnlyStandard}
        onlyActive={onlyActive}
        setOnlyActive={setOnlyActive}
      />

      <Segment>
        <div className="flex--right">
          {/* <ExportButton
              fileName={fileName}
              total={total}
              filters={filters}
              // mapData={mapData}
              url="/services/export"
            /> */}
          <ItemsPerPage
            style={{ marginLeft: "10px" }}
            total={total}
            value={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>

        <ServicesQuotationTable
          handleToggle={handleToggle}
          deleteServiceQuotation={handleDelete}
          serviceQuotations={serviceQuotations}
          isLoading={isLoading}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          // isHeaderChecked={isHeaderChecked}
          // checkedItems={checkedItems}
          // handleSingleCheck={handleSingleCheck}
          // handleMultipleCheck={handleMultipleCheck}
        />
        {total > itemsPerPage && (
           <Pagination
                     style={{ marginTop: "1em" }}
                     activePage={currentPage}
                     totalPages={Math.ceil(total / itemsPerPage)}
                     boundaryRange={1}
                     siblingRange={1}
                     firstItem={null}
                     lastItem={null}
                     onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
                   />
        )}
      </Segment>
    </main>
    // </CanViewPage>
  );
}

export default Quotations
