import React, { useState, useEffect } from "react";
import Lowstockperwarehouse from "./warehouse_total";
import Lowstock_per_product from "./product_total";
import { Button, Divider } from "rsuite";

import { useUpdateContext } from "../../../context/UpdateContext";
import { getLowStockProducts } from "../../../actions/reports";

function Lowstock(props) {
  const { setLowStockWarehouseView } = useUpdateContext();

  const [businessclients, setBusinessclients] = useState([]);
  const [showPerProduct, setShowPerProduct] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const styles = { width: "370px", marginRight: "20px" };

  useEffect(() => {
    setLoading(true);
    getLowStockProducts(businessclients)
      .then((resp) => {
        setLowStockWarehouseView(resp);
        localStorage.setItem("lowstock", JSON.stringify(resp));
        localStorage.setItem("lowstock_date", new Date());
      })
      .then((resp) => setLoading(false));
      
  }, [businessclients]);
  return (
    <div style={{ margin: "20px" }}>
      <div className="flex--left">
        <Button
          style={styles}
          color="orange"
          disabled={!showPerProduct}
          onClick={() => setShowPerProduct(false)}
        >
          Show Warehouse View
        </Button>
        <Button
          style={styles}
          color="green"
          disabled={showPerProduct}
          onClick={() => setShowPerProduct(true)}
        >
          Show Product View
        </Button>
      </div>

      <Divider></Divider>
      {!showPerProduct && (
        <Lowstockperwarehouse
          setBusinessclients={setBusinessclients}
          isLoading={isLoading}
        />
      )}

      {showPerProduct && <Lowstock_per_product />}
    </div>
  );
}

export default Lowstock;
