import React, {useEffect,useState } from "react";
import { getAssignedOrders } from "../../../actions/packing";
import OrdersTable from "../../Order/ListOrders/OrdersTable";

function DraftList(props) {

  const [orders,setOrders] = useState([])
  useEffect(() => {
    getAssignedOrders().then(resp=>setOrders(resp))
  }, []);

  return (
    <section>
      <OrdersTable
        viewActionsButton={false}
        viewExportButton={false}
        orders={orders}
        // checkedItems={this.state.checkedItems}
        // headerIsChecked={this.state.headerIsChecked}
        // handleSingleCheck={this.handleSingleCheck}
        // handleMultipleCheck={this.handleMultipleCheck}
        currentPage={1}
        itemsPerPage={orders.length}
        checkColumn={false}
        actionColumn={false}
        // isLoading={isLoading}
        orderLink="/packer/orders/"
        sortable={false}
      />
    </section>
  );
}


export default (DraftList);
