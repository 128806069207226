import React from "react";
import {
  Segment,
  Header,
  Checkbox,
  Divider,
  Input,
  Label,
} from "semantic-ui-react";
import WarehouseFilter from "../../Filters/WarehouseFilter";

function APIsettings({ courier, updateCourier }) {
  return (
    <section>
      <div className="flex--between vertical--center mt--2">
        <Header as="h2">API settings</Header>
        {/* <Button onClick={() => openModal(false)}>Add New</Button> */}
      </div>
      <Segment padded>
        <div>
          <h6>Sticker printing</h6>
          <div class="flex--left">
            <p style={{ marginRight: "10px" }}>
              Courier AWB Tracking PDF is generated localy
            </p>
            <Checkbox
              name="settings.sticker_pdf_local"
              value={!courier.settings.sticker_pdf_local}
              checked={courier.settings.sticker_pdf_local}
              onChange={(e, data) => updateCourier(data)}
            ></Checkbox>
          </div>
          <div class="flex--left">
            <p style={{ marginRight: "10px" }}>Print separate order Barcode</p>
            <Checkbox
              name="settings.printBarcodeOrder"
              value={!courier.settings.printBarcodeOrder}
              checked={courier.settings.printBarcodeOrder}
              onChange={(e, data) => updateCourier(data)}
            ></Checkbox>
          </div>
          <div class="flex--left">
            <p style={{ marginRight: "10px" }}>
              Sender Address- Display Warehouse Address on sticker
            </p>
            <Checkbox
              name="settings.sticker_senderAddressIsWarehouse"
              value={!courier.settings.sticker_senderAddressIsWarehouse}
              checked={courier.settings.sticker_senderAddressIsWarehouse}
              onChange={(e, data) => updateCourier(data)}
            ></Checkbox>
          </div>
          <p>Tracking type:{courier.settings.trackingType}</p>
        </div>
        <Divider></Divider>
        <div class="flex-between">
          <h6>Pricing type</h6>
          <Checkbox
            label="fixed"
            value={"fixed"}
            name="settings.billing_type"
            checked={courier.settings.billing_type === "fixed"}
            onChange={(e, data) => updateCourier(data)}
          ></Checkbox>
          <Checkbox
            label="relative"
            name="settings.billing_type"
            value={"relative"}
            checked={courier.settings.billing_type === "relative"}
            onChange={(e, data) => updateCourier(data)}
          ></Checkbox>
        </div>
        <Divider></Divider>
        <h6>Partner warehouse</h6>
        <WarehouseFilter
          useFilter={false}
          multiple={false}
          value={courier.partnerWarehouse}
          name="partnerWarehouse"
          handleChange={(value) =>
            updateCourier({ name: "partnerWarehouse", value:value?value:null })
          }
          // error={showError({ field: "location" })}
        />
        <Label>External Id</Label>
        <Input
          value={courier.settings.external_id}
          name="settings.external_id"
          onChange={(e, data) => updateCourier(data)}
        ></Input>

        <Divider></Divider>
      </Segment>
    </section>
  );
}

export default APIsettings;
