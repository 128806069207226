import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";

import ExportExcel from "../ExportExcel";

function ExportButton(props) {
  const { filename, data, style = {}, key_name } = props;

  const [dataSet, setData] = useState();
  
  const colNames = [{ label: key_name, value: "id" }];

  useEffect(() => {
    const received = data.map((item) => ({
      label: key_name,
      id: item,
    }));
    setData(received);
  }, [data]);

  return (
    <ExportExcel
      filename={filename}
      excelSheetName="list"
      dataSet={dataSet}
      colNames={colNames}
      button={
        <Button
          style={style}
          basic
          color="grey"
          disabled={!data || !data.length}
        >
          <Icon name="excel file" />
          Export {`${data && data.length}`}
        </Button>
      }
    />
  );
}
export default ExportButton;
