import { apiCall } from "../services/api";

export const courierCodUpload = (props) =>  {
  return new Promise((resolve,reject)=>{
        const url = `${process.env.REACT_APP_API}/couriercodupload/`;
        apiCall("post", url, props)
          .then((data) =>resolve(data))
          .catch((err) =>reject(err.message))
  })

};