import { combineReducers } from "redux";
import user from "./currentUser";
import notifications from "./notifications";
import filtersmain from "./filtersmain";
import droplists from "./droplists";

const appReducer = combineReducers({

  user,
  notifications,
  filtersmain,
  droplists,
});
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
