import React from "react";
import { Segment, Checkbox } from "semantic-ui-react";
// import { DateRangePicker } from "rsuite";

// import CountryFilter from '../Filters/CountryFilter'
// import ClientFilter from "../Filters/ClientFilter";
// import ServiceTypes from "../Filters/ServiceTypes";

function Filters({
  //  country ,
  //  setCountry,
  onlyRelative,
  setOnlyRelative,
  category,
  setCategory,
}) {
  return (
    <Segment>
      <div className="flex--left">
        <Checkbox
          onChange={() => {
            if (onlyRelative !== "relative") setOnlyRelative("relative");
            else setOnlyRelative("");
          }}
          checked={onlyRelative === "relative"}
          label="only relative rates"
        ></Checkbox>
        <div style={{ marginLeft: "10px" }}>
          <Checkbox
            onChange={() => {
              if (category !== "shipping") setCategory("shipping");
              else setCategory("");
            }}
            checked={category === "shipping"}
            label="Shipping"
          ></Checkbox>
          <Checkbox
            onChange={() => {
              if (category !== "warehouse") setCategory("warehouse");
              else setCategory("");
            }}
            checked={category === "warehouse"}
            label="Warehouse"
          ></Checkbox>
          <Checkbox
            onChange={() => {
                if (category !== "pack_material") setCategory("pack_material")
                else setCategory("");
              
            }}
            checked={category === "pack_material"}
            label="Packing material"
          ></Checkbox>
        </div>
      </div>
    </Segment>
  );
}
export default Filters;
