import React from "react";
import { Timeline,Divider } from "rsuite";
import Moment from "react-moment";

function Services({ services }) {
  if (!services || !services.length) return <div>
          <div style={{'marginBottom':'10px'}} className='flex_spaceBetween'>
            <h6 >Services</h6>
            <div className="bold--text" >0</div>
            </div>
            <Divider />
  <p>No service provided.</p>
  </div>;

  let total =0
  
  for (let i=0; i<services.length;i++) {
    total=total+services[i].amount
  }

  const timelineItems = () => {
    let items = [];
    for (let i=0; i<services.length;i++) {
      const { amount, serviceType, createdAt,currency,description } = services[i];
 
      const item = (
        <Timeline.Item
          key={i}
          className={`rs-timeline-item ${
            i === services.length - 1 ? "rs-timeline-item-first" : ""
          }`}
        >
          <p>
            <Moment format="DD-MM-YY HH:mm">{createdAt}</Moment> <br></br> 
            <div className="bold--text" >{serviceType.name} - {amount.toFixed(2)} {currency}</div> 
            {description&&<div className="smallinfo" >({description})</div> }
        
                
          </p>
        </Timeline.Item>
      );
      items.push(item);
    }
    return items;
  };

  return (
  <div>
    <div style={{'marginBottom':'10px'}} className='flex_spaceBetween'>
      <h6 >Services</h6>
      <div className="bold--text" >{total.toFixed(2)} {services[0].currency}</div>
      </div>
      <Divider />
 
    <div><Timeline>{timelineItems()}</Timeline></div>

    
    </div>)
}

export default Services;
