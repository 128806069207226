
import { apiCall } from "../services/api";

export const getInitialPickingList = (body) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/picking`;

    apiCall("post", url,body)
      .then((resp) => resolve(resp))
      .catch((err) => {
        reject(err.message);
      });
  });
};



export const updateOrdersStatusPicking = ({ orders, limit }) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/picking/orders`;
    apiCall("post", url, {orders,limit})
      .then((resp) => resolve(resp))
      .catch((err) => {
        reject(err.message);
      });
  });
};

