import React from "react";
import { Button } from "semantic-ui-react";
// import { Link } from "react-router-dom";

function Prints({
  handleAWBPrint,
  handleBarcodePrint,
  handleInvoicePrint,
  awbDatalink,
}) {
  return (
    <table className="print-table">
      <tbody>
        <tr className="print-table__row">
          <td className="print-table__label">Invoice</td>

          <td className="print-table__button">
            <Button
              basic
              circular
              size="tiny"
              icon="print"
              onClick={handleInvoicePrint}
            ></Button>
          </td>
        </tr>

        <tr className="print-table__row">
          <td className="print-table__label">
            Label - 
            <a href={awbDatalink} target="_blank">
              view
            </a>
          </td>

          <td className="print-table__button">
            <Button
              basic
              circular
              size="tiny"
              icon="print"
              onClick={handleAWBPrint}
            ></Button>
          </td>
        </tr>

        <tr className="print-table__row">
          <td className="print-table__label">Barcode</td>

          <td className="print-table__button">
            <Button
              basic
              circular
              size="tiny"
              icon="print"
              onClick={handleBarcodePrint}
            ></Button>
          </td>
        </tr>
        {/* <tr>
          <td><Button basic  circular onClick={handlePrintUrls}>U</Button></td>
        </tr> */}
      </tbody>
    </table>
  );
}

export default Prints;
