import React, { useEffect, useState } from "react";
import { Header, Segment, Pagination } from "semantic-ui-react";
import {
  getServiceTypes,
  createServiceType,
  deleteServiceType,
} from "../../actions/servicetypes";
import ItemsPerPage from "../ItemsPerPage";
import ServiceTypesTable from "./ServiceTypesTable";
import Filters from "./Filters";
import ServiceType from "./Servicetype";
import "./services.scss";
// import servicetypes from "../../reducers/servicetypes";
import { Alert } from "rsuite";

function ServiceTypes(props) {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [onlyRelative, setOnlyRelative] = useState("");
  const [country, setCountry] = useState("");
  const [category, setCategory] = useState("");
  const [list, setList] = useState({data:[],total:0});
  const [isLoading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // const {
  //   isHeaderChecked,
  //   checkedItems,
  //   handleSingleCheck,
  //   handleMultipleCheck,
  //   handleClearCheckedItems,
  // } = useTableCheckbox(servicetypes);

  useEffect(() => {
    setLoading(true);
    getServiceTypes({
      country,
      onlyRelative,
      category,
      itemsPerPage,
      currentPage,
    })
      .then((resp) => {
        setLoading(false);
        setList({ data: resp.list, total: resp.total });
      })
      .catch((err) => Alert.error(err));
  }, [country, onlyRelative, category, itemsPerPage, currentPage]);

  // const { dateFrom, dateTo, status } = filters;
  // const [fileName, setFileName] = useState("services");

  // useEffect(
  //   () =>
  //     setFileName(
  //       `services${dateFrom ? " - " + dateFrom.toLocaleDateString() : ""}${
  //         dateTo ? " - " + dateTo.toLocaleDateString() : ""
  //       }${status === "false" ? " - not billed" : ""}${
  //         status === "true" ? " - billed" : ""
  //       }`
  //     ),
  //   [dateFrom, dateTo, status]
  // );

  return (
    // <CanViewPage path="/services">
    <main className="page-wrapper">
      <div class="flex--between">
        <Header>Service types</Header>
        <ServiceType createServiceType={createServiceType}></ServiceType>
      </div>

      <Filters
        onlyRelative={onlyRelative}
        setOnlyRelative={setOnlyRelative}
        category={category}
        setCategory={setCategory}
        country={country}
        setCountry={setCountry}
      />

      <Segment>
        <div className="flex--right">
          {/* <ExportButton
              fileName={fileName}
              total={total}
              filters={filters}
              // mapData={mapData}
              url="/services/export"
            /> */}
          <ItemsPerPage
            style={{ marginLeft: "10px" }}
            total={list.total}
            value={itemsPerPage}
            setItemsPerPage={(value) => setItemsPerPage(value)}
          />
        </div>

        <ServiceTypesTable
          deleteServiceType={(id) =>
            deleteServiceType(id).then((resp) => setRefresh(!refresh))
          }
          services={list.data}
          isLoading={isLoading}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          // isHeaderChecked={isHeaderChecked}
          // checkedItems={checkedItems}
          // handleSingleCheck={handleSingleCheck}
          // handleMultipleCheck={handleMultipleCheck}
        />
        {list.total > itemsPerPage && (
          <Pagination
            style={{ marginTop: "1em" }}
            activePage={currentPage}
            totalPages={Math.ceil(list.total / itemsPerPage)}
            boundaryRange={1}
            siblingRange={1}
            firstItem={null}
            lastItem={null}
            onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          />
        )}
      </Segment>
    </main>
    // </CanViewPage>
  );
}

export default ServiceTypes;
