import React, { useState, useEffect } from "react";
import { Divider } from "rsuite";
import { Card, CardContent } from "semantic-ui-react";
import { ordersWarehouse } from "../../actions/reports";

const Orderswarehouse = ({ isMobile }) => {
  const [list, setList] = useState([]);
  const [viewMore, setViewMore] = useState(true);

  useEffect(() => {
    ordersWarehouse()
      .then((resp) => setList(resp))
      .catch((err) => console.log(err));
  }, []);

  let rows = [];

  if (viewMore)
    rows = list.map((item) => (
      <tr>
        <td>{item.warehouse_name}</td>
        <td>{item.total}</td>
      </tr>
    ));
  else
    rows = (
      <tr>
        <td>{list[0]?.warehouse_name}</td>
        <td>{list[0]?.total}</td>
      </tr>
    );

  return (
    <div style={{ margin: "10px" }}>
      {/* <Header>Orders per warehouse</Header> */}
      <Card.Group>
        <Card color="teal" fluid={isMobile}>
          <CardContent>
            <Card.Header>Orders To Pack</Card.Header>
          </CardContent>
          <CardContent>
            <Card.Description>
              <table>{rows}</table>
            </Card.Description>
          </CardContent>
          <CardContent extra>
            {!viewMore && (
              <a onClick={() => setViewMore(true)}> view all {list.length}</a>
            )}
            {viewMore && <a onClick={() => setViewMore(false)}> Hide</a>}
          </CardContent>
        </Card>
      </Card.Group>

      {/* <Divider></Divider> */}
    </div>
  );
};

export default Orderswarehouse;
