import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Header, Button, Divider, Tab } from "semantic-ui-react";
import { Alert } from "rsuite";
import {
  getProduct,
  updateProduct,
  getMovements,
  deleteProduct,
} from "../../../actions/product";
import CanView from "../../../role-based-access/CanView.jsx";

import CanViewPage from "../../../role-based-access/CanViewPage";
import Documents from "../../../components/Documents";
// import Relatedproducts from "./related";
import ProductInfoGrid from "./ProductInfoGrid";
import { Stockmovements } from "./stockmovements";
import Stockavailable from "./stockavailable";
import Material from "./material.jsx";

import "../product.scss";

const EditViewProduct = (props) => {
  const { id } = props.match.params;
  
  const [updated, setUpdate] = useState(false);
  const [state, setState] = useState({
    updates: {},
    product: {
      proprities: { dimentions: { height: "" } },
      businessclient: null,
      _id: null,
      codes: { sku: null },
    },
  });

  const [stocks, setStockmoves] = useState({
    warehouseTotal: [],
    stockmoves: { total: 0, movements: [] },
  });

  const [filters, setFilters] = useState({
    dateFrom: null,
    dateTo: null,
    clientOrderNr: "",
    currentPage: 1,
    itemsPerPage: 10,
  });

  const [isLoading, setLoading] = useState(true);

  const handleDeleteProduct = () => {
    deleteProduct({
      id: id,
      history: props.history,
    }).then((resp) => {});
  };
  const handleTabChange = (e, { activeIndex }) => {
    props.history.push(`/products/${id}`);
    setState({ activeIndex });
  };

  const handleFormChange = (e, { name, value }) => {
    updateProduct(id, { [name]: value }).then((resp) => {
      if (resp._id)
        setState({
          ...state,
          formHasChanges: false,
          product: resp,
          updates: {},
        });

      setLoading(false);
    });
  };

  useEffect(() => {
    getProduct(id)
      .then((resp) => {
        setState({ ...state, product: resp.foundProduct });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Alert.error(error.message);
      });
  }, [updated]);

  useEffect(() => {
    setLoading(true);
    id &&
      getMovements({ id, filters }).then((resp) => {
        setLoading(false);
        setStockmoves(resp);
      });
  }, [filters, id]);

  const { product, activeIndex } = state;

  const productInfo = (
    <div className="">
      <ProductInfoGrid
        handleFormChange={handleFormChange}
        productForm={product}
      />
    </div>
  );

  const panes = [
    {
      menuItem: "General",
      render: () => (
        <div className="product">
          {productInfo}

          {/* <Productcards
              getCardData={(data)=>console.log(data)}
              products={productsdata}
            /> */}

          <Divider></Divider>

          <Material
            id={id}
            packing_material={product.packing_material}
            updated={updated}
            setUpdate={setUpdate}
          ></Material>
          {/* <Pricing
            isLoading={props.isLoading}
            productId={props.match.params.id}
          /> */}
          <Divider></Divider>

          <Stockavailable id={props.match.params.id} />
          <Documents
            trigger="Product"
            triggerId={props.match.params.id}
            businessclient={product.businessclient}
          />
          <Stockmovements
            filters={filters}
            setFilters={(data) => setFilters(data)}
            stockMovements={stocks.stockmoves}
            loading={isLoading}
          />
        </div>
      ),
    },
    // {
    //   menuItem: "Promos",
    //   render: () => <div>test</div>,
    // },
    // {
    //   menuItem: "Related Products",
    //   render: () => (
    //     <div>
    //       <Segment>
    //         <Relatedproducts
    //           // relatedProducts={product.relatedProducts}
    //           businessclient={product.businessclient._id}
    //           productId={product._id}
    //         ></Relatedproducts>
    //       </Segment>
    //     </div>
    //   ),
    // },
  ];

  return (
    <CanViewPage path="/products/:id">
      <div style={{ maxWidth: "1100px", margin: "30px" }}>
        <div className="flex_spaceBetween">
          <div className="">
            <Header as="h1">
              {/* <span className="grey--text">Product: </span>  */}
              {product.name}
            </Header>
            <span className="smallinfo">
              {product.businessclient && product.businessclient.name}
            </span>
          </div>
          <CanView path="/products/:id" action="delete">
            <Button basic color="red" onClick={handleDeleteProduct}>
              Delete product
            </Button>
          </CanView>
        </div>

        {/* <div className="product">
            {productInfo}
            <Pricing
              isLoading={props.isLoading}
              pricing={props.product.pricing}
              productId={props.product._id}
            />


            <Stockavailable stockTotals={props.stockTotals} />
            <Stockmovements movements={stockMovements} />
          </div> */}

        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
        />
      </div>
    </CanViewPage>
  );
};

export default connect(null, {})(EditViewProduct);
