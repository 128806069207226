import React from "react";
import Deliveryratecards from "./Reports/Deliveryratecards";
import Orderswarehouse from "./Reports/Report_orders_warehouse";
import Nogoodsorders from "./Reports/Report_no_goods_general";
import { Divider, Header } from "semantic-ui-react";

const Myhome = (props) => {
  return (
    <div style={{ margin: "30px" }}>
      <Header>My Home</Header>
      <div className="flex--left">
        <Orderswarehouse />
        <Nogoodsorders />
      </div>
      <Divider></Divider>
      <Deliveryratecards
      // isMobile={isMobile}
      // dateFrom={dateFrom}
      // dateTo={dateTo}
      // courier={courier}
      // product={product}
      // businessclient={businessclient}
      // warehouse={warehouse}
      ></Deliveryratecards>
    </div>
  );
};

export default Myhome;
