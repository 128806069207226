import React, { useState, useEffect } from "react";
import { Divider, Form, Header } from "semantic-ui-react";

import {
  getInitialPickingList,  
  updateOrdersStatusPicking,

} from "../../../actions/picking";
// import { getCouriersByWarehouse } from "../../../../actions/couriers";

import CanViewPage from "../../../role-based-access/CanViewPage";
import TopBar from "./TopBar";
import PickerListByCourier from "./PickerListByCourier";
import PickerListByProduct from "./PickerListByProduct";
import { Alert } from "rsuite";
import "../picking.scss";
import "../picker.scss";
import { useUpdateContext } from "../../../context/UpdateContext";


const PickerOrdersV2 = (props) => {
  const [isLoading, setIsloading] = useState(false);
  const [lists, setLists] = useState({ priorityList: [], pickingList: [] });
  const [groupBy, setGroupBy] = useState("courier");
  const [updateList, setUpdateList] = useState(false);
  const { priorityList, pickingList } = lists;

  const {
    businessclient,
    product,
    dateFrom,

  } = useUpdateContext();

  console.log("PRODUCT:",product)

  const handleStartPicking = ({ orders, limit }) => {
    setIsloading(true);

    updateOrdersStatusPicking({ orders, limit })
      .then((rep) => setUpdateList(!updateList))
      .then((resp) => Alert.success("Orders moved to picking"))

      .catch((err) => Alert.error(err));
  };

  useEffect(() => {
    setIsloading(true);
    getInitialPickingList({ dateFrom, products: product, businessclient })
      .then((resp) => {
        setIsloading(false);
        setLists(resp);
      })
      .catch((error) => {
        setIsloading(false);
        Alert.error(error);
      });
  }, [businessclient, dateFrom, product,updateList]);

  return (
    <CanViewPage path="/picker/orders">
      <>
        <div
          className="a-wrapper"
          style={{ marginTop: "1.3em", marginBottom: "0em" }}
        >
          <div className="d-flex--jc-between">
            <Header as="h3">Product Picking V2</Header>
          </div>
        </div>
        {/* <Divider></Divider> */}
        <TopBar />
        <div
          className="a-wrapper"
          style={{ marginTop: "4em", marginBottom: "7em" }}
        >
          {priorityList.length > 0 && (
            <div>
              <Form loading={isLoading}>
                <Header> PRIORITY LIST</Header>
                {/*  */}
                <div style={{ borderStyle: "dashed", maxWidth: "1100px" }}>
                  {groupBy === "courier" && (
                    <PickerListByCourier
                      list={priorityList}
                      handleStartPicking={handleStartPicking}
                    />
                  )}
                  {groupBy === "product" && (
                    <PickerListByProduct
                    // list={pickingList}
                    // handleInputOrders={this.handleInputOrders}
                    // handleViewList={this.handleViewList}
                    // selection={this.state.selection}
                    />
                  )}
                </div>
              </Form>
            </div>
          )}
          <Divider></Divider>
          {pickingList.length > 0 && (
            <Form loading={isLoading}>
              <Header>TODAY LIST</Header>
              <div style={{ maxWidth: "1100px" }}>
                {groupBy === "courier" && (
                  <PickerListByCourier
                    list={pickingList}
                    handleStartPicking={handleStartPicking}
                  />
                )}
                {groupBy === "product" && (
                  <PickerListByProduct
                  // list={
                  //   //filter list based on search products, display by product
                  //   searchedProducts.length
                  //     ? this.filterByProduct(this.groupListByProdut(list))
                  //     : this.groupListByProdut(list)
                  // }
                  // handleInputOrders={this.handleInputOrders}
                  // handleViewList={this.handleViewList}
                  // selection={this.state.selection}
                  />
                )}
              </div>
            </Form>
          )}
        </div>
      </>
    </CanViewPage>
  );
};

export default PickerOrdersV2;
