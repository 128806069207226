import React, { useState} from "react";
import { Button, Divider,  Segment } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { courierCostUpload } from "../../actions/courier_cost_upload";
import { Alert, DatePicker } from "rsuite";
import Newteamplate from "./newcostteamplate";
import ExportButton from "./ExportButton";

function getRowStyle({ row, column, template, cost_desc_position }) {
  if (template.title_row == row + 1) return { backgroundColor: "#7fbada" };

  //highlight id
  if (
    // column + 1 == template.look_up_key_cln && template.title_row < row + 1
    template.look_up_key_clns.includes(JSON.stringify(Number(column) + 1))

    )

    return { backgroundColor: "#ffda37" };

  if (
    cost_desc_position === "in_row" &&
    column + 1 == template.description_cln &&
    template.title_row < row + 1
  )
    return { backgroundColor: "rgb(255 220 132)" };

  //highlight cost  amounts
  if (
    template.cost_amount_clns.includes(JSON.stringify(Number(column) + 1))
    // &&template.title_row < row + 1
  )
    return { backgroundColor: "rgb(33 186 66)" };
}
const initResp = {
  grandTotal: 0,
  errorRows: [],
};

const initTemplate = {
  look_up_key_clns:[1],
  look_up_key: "shipping.trackingId",
  cost_amount_clns: [],
  cost_desc_position: "in_cln_title",
  description_cln: 3,
  title_row: 1,
};

function Upload_COST(props) {
  const [courier, setCourier] = useState();
  const [period, setPeriod] = useState();
  const [template, setTemplate] = useState(initTemplate);
  const [rawData, setRawdata] = useState();
  const [visualizedData, setVisualizedData] = useState([]);
  const [columnTotal, setColumnTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadResponse, setUplaodResponse] = useState(initResp);

  // console.log(template.cost_amount_clns);

  // useEffect(() => {
  //   setUplaodResponse(initResp);

  // }, [rawData, template]);

  // console.log("2.data to upload:", uploadedData[1]);

  // useEffect(() => {
  //   if (rawData) {
  //     let total = 0;
  //     uploadedData.map((row) => {
  //       // console.log("row:", parseFloat(row[columnIndex]));
  //       total = total + (parseFloat(row[1]) || 0);
  //     });
  //     setColumnTotal(total);
  //   }
  // }, [rawData, uploadedData]);

  const onDrop = (acceptedFiles) => {
    setLoading(true);
    setVisualizedData([]);
    const reader = new FileReader();

    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      setRawdata(jsonData);
      // console.log(jsonData);
      // Display first 5 rows
      setVisualizedData(jsonData.slice(0, 15));
      setLoading(false);
    };

    reader.readAsBinaryString(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
  });

  const handleUploadData = () => {
    courierCostUpload({
      courier,
      data: rawData,
      template: template,
    })
      .then((resp) => {
        setUplaodResponse(resp);
      })
      .catch((error) => Alert.error(error));
  };

  return (
    <div
      className="App"
      style={{ marginTop: "20px", marginLeft: "30px", maxWidth: "1200px" }}
    >
      <Segment>
        <h4>Cost File Uploader</h4>
        <div
          {...getRootProps()}
          style={{ border: "2px dashed #ccc", padding: "20px", margin: "20px" }}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop an Excel file here, or click to select file</p>
        </div>
      </Segment>
      {loading && <div>Loading table...</div>}
      {visualizedData.length > 0 && (
        <div>
          <Divider></Divider>
          <Newteamplate
            template={template}
            setTemplate={setTemplate}
            courier={courier}
            setCourier={setCourier}
          ></Newteamplate>

          <div id="courier template"></div>

          <Divider></Divider>

       
          <Segment className="card_">
          <h4>Preview Document 5 Rows:</h4>
            <table className="hoverTable dataTable">
              <tbody>
                {visualizedData.map((row, i) => (
                  <tr key={i}>
                    {row.map((cell, j) => (
                      <td
                        style={getRowStyle({
                          template,
                          row: i,
                          column: j,
                          cost_desc_position: template.cost_desc_position,
                        })}
                        key={j}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Segment>
        </div>
      )}
      <Divider />

      <Segment style={{ minHeight: "150px" }}>
        <Button
          disabled={!rawData}
          color="green"
          onClick={() => handleUploadData()}
          style={{ width: "300px" }}
        >
          Check data
        </Button>
        <Divider></Divider>
        <p>Total: {uploadResponse.grandTotal.toFixed(2)}</p>
        <p>Error lines: {uploadResponse.errorRows.length}</p>
        {uploadResponse.errorRows && uploadResponse.errorRows.length > 0 && (
          <ExportButton
            style={{ marginBottom: "10px" }}
            data={uploadResponse.errorRows}
            filename={"Missing Ids"}
          ></ExportButton>
        )}
      </Segment>
      <Divider />
      <Segment style={{ minHeight: "450px" }}>
        <div>
          <h4>Select Fiscal Period</h4>
          <DatePicker
            style={{ width: "200px" }}
            onPrevMonth={true}
            format="YYYY-MM"
            ranges={[]}
            onChange={(value) => console.log("date;", value)}
          />
        </div>
        <Divider></Divider>
        <Button
          disabled={!rawData}
          color="blue"
          onClick={() => handleUploadData()}
          style={{ width: "300px" }}
        >
          Upload Costs
        </Button>
      </Segment>
    </div>
  );
}

export default Upload_COST;
