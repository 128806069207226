import React, { useState, useEffect, useRef } from "react";
import { apiCall } from "../../services/api";
import {
  Segment,
  FormField,
  Radio,
  Form,
  FormGroup,
  Divider,
  Header,
  Message,
  MessageHeader,
  Table,
  Button,
} from "semantic-ui-react";
import CourierFilter from "../Filters/CourierFilter";

const successSound = new Audio("/positive-1.mp3");
const failSound = new Audio("/negative-1.mp3");

const BarcodeInput = () => {
  const [inputType, setInputType] = useState("scanner");
  const [manualInput, setManualInput] = useState("");

  const [scannedType, setBarcodeType] = useState("internalNumericId");
  const [courier, setCourier] = useState();
  const [error, setError] = useState();

  const [orderItems, setItems] = useState([]);
  const [barcode, setBarcode] = useState("");

  const [readValue, setReadValue] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const inputRef = useRef(null); // Ref for the input element
  const debounceTimeout = 1000; // 1 second debounce

  const playSound = (audio) => {
    // console.dir(audio.ended);
    // console.dir(audio.currentTime);
    if (!audio.ended) {
      audio.currentTime = 0;
    }
    audio.play();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (barcode && !isProcessing) {
        submitBarcode(barcode);
      }
    }, debounceTimeout);

    return () => clearTimeout(timer); // Cleanup on value change or unmount
  }, [barcode, isProcessing]);

  const handleKeyPress = (event) => {
    // console.log(`Key pressed: ${event.key}, Code: ${event.code}`);
    if (event.key === "Tab") {
      event.preventDefault(); // Prevent default Tab behavior if necessary
      console.log("Tab key was pressed");
    }
  };

  const submitBarcode = async (barcode) => {
    setError();
    setIsProcessing(true);
    setItems([]);
    setManualInput("")
    try {
      const url = `${process.env.REACT_APP_API}/packer/orders/set/notshipped?id=${barcode}&scannedType=${scannedType}&courier=${courier}`;
      apiCall("get", url)
        .then((resp) => {
          // alert('in resp')
          if (resp.status === "success") {
            setItems(resp.order.items);
            playSound(successSound);
          }
          if (resp.status === "error") {
            playSound(failSound);
          }
        })
        .catch((err) => {
          console.log(err);
          setError(err.message);
          playSound(failSound);
        });

      //   alert("Barcode submitted successfully!");
    } catch (error) {
      console.error("Error submitting barcode:", error);
      alert("Failed to submit barcode");
    } finally {
      setIsProcessing(false);
      setReadValue(barcode);
      setBarcode(""); // Clear input after submission
      inputRef.current.focus(); // Return focus to the input field
    }
  };

  useEffect(() => {
    setItems([]);
    setError();
    setReadValue();
    inputRef.current.focus(); // Return focus to the input field
  }, [courier, scannedType,inputType]);

  return (
    <Segment>
      <Form>
        <Header>Input type</Header>
        <FormGroup inline>
          <FormField>
            <Radio
              label="Scanner"
              name="inputType"
              value="scanner"
              checked={inputType === "scanner"}
              onChange={(e, { value }) => setInputType(value)}
            />
          </FormField>
          <FormField>
            <Radio
              label="Manual"
              name="inputType"
              value="manual"
              checked={inputType === "manual"}
              onChange={(e, { value }) => setInputType(value)}
            />
          </FormField>
        </FormGroup>
      </Form>
      {inputType === "scanner" && <Header> SCAN ID - {scannedType}</Header>}
      {inputType === "manual" && <Header> ENTER MANUALY - {scannedType}</Header>}
      <Divider></Divider>
      <Form>
        <FormGroup inline>
          <FormField>
            <Radio
              label="Internal Id"
              name="radioGroup"
              value="internalNumericId"
              checked={scannedType === "internalNumericId"}
              onChange={(e, { value }) => setBarcodeType(value)}
            />
          </FormField>
          <FormField>
            <Radio
              label="Tracking ID"
              name="radioGroup"
              value="AWB"
              checked={scannedType === "AWB"}
              onChange={(e, { value }) => setBarcodeType(value)}
            />
          </FormField>
        </FormGroup>
        <FormField>
          {/* Selected value: <b>{scannedType}</b> */}
        </FormField>
      </Form>
      {scannedType == "AWB" && (
        <>
          <Divider></Divider>

          <CourierFilter
            multiple={false}
            useFilter={false}
            handleChange={(value) => setCourier(value)}
          ></CourierFilter>
        </>
      )}
      <Divider></Divider>
      <input
        style={{ width: "400px" }}
        ref={inputRef} // Attach the ref to the input element
        type="text"
        value={inputType === "scanner" ? barcode : manualInput}
        onChange={(event) => {
          event.preventDefault(); // Prevent default Tab behavior if necessary

          inputType === "scanner"
            ? setBarcode(event.target.value)
            : setManualInput(event.target.value);
        }}
        placeholder={
          inputType === "scanner"
            ? "Scan barcode here"
            : "Start typeing id manualy..."
        }
        onKeyDown={handleKeyPress} // Detect Tab press
      />
      {inputType === "manual" && (
        <Button onClick={() => submitBarcode(manualInput)}>Submit</Button>
      )}
      {isProcessing && <p>Processing...</p>}
      {readValue && <p>Scanned Value: {readValue}</p>}
      <Divider></Divider>

      {orderItems.length > 0 && (
        <>
          <p>Items in Order</p>
          <Table style={{ maxWidth: "300px" }}>
            {" "}
            {orderItems.map((item) => (
              <tr>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
              </tr>
            ))}
          </Table>
        </>
      )}
      {error && (
        <>
          <Message negative>
            <MessageHeader> !{error}</MessageHeader>
          </Message>
        </>
      )}
    </Segment>
  );
};

export default BarcodeInput;
