import React from "react";
import { Segment } from "semantic-ui-react";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import ProductFilterList from "../../Filters/ProductFilterList";



function TopBar() {
  return (
    <Segment>
      <div className="a-wrapper d-flex--jc-between">
        <div className="top-filters__container">
          {/* <div className="top-filters__box">
            <span style={{ marginRight: "1.5em" }}>Group By</span>
            <div className="top-filters__column">
              <Button.Group>
                <Button
                  style={{ marginBottom: "0.4em" }}
                  onClick={() => chooseGroupBy("courier")}
                  // basic={groupBy !== "courier"}
                  color={groupBy === "courier" ? "yellow" : ""}
                >
                  Courier
                </Button>
                <Button.Or text="or" />
                <Button
                  style={{ marginBottom: "0.4em" }}
                  onClick={() => chooseGroupBy("product")}
                  color={groupBy === "product" ? "yellow" : ""}
                >
                  Product
                </Button>
              </Button.Group>
            </div>
          </div> */}

          <div className="top-filters__box" style={{ marginBottom: "0.4em" }}>
            {/* <span style={{ marginRight: "1.8em" }}>Filter By</span> */}
            <BusinessclientFilter useFilter={true}></BusinessclientFilter>
          
            <ProductFilterList multiple={true}></ProductFilterList>

            {/* <DatePicker
              // disabled={isLoading}
              oneTap
              value={dateTo}
              isClearable={true}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) => setDateTo(date)}
              placeholder="to..."
              style={{ width: "200px",marginLeft:"20px" }}
            ></DatePicker> */}
          </div>
        </div>
      </div>
    </Segment>
  );
}

export default TopBar;
