import React, { useState, useEffect } from "react";
import { Header, Table, Button, Input } from "semantic-ui-react";
import EditableText from "../../EditableText";
import {
  updateProductPackingMaterial,
  addPackingmaterial,
  deletePackingmaterial,
} from "../../../actions/product";
import ServiceTypes from "../../Filters/ServiceTypes";

// const packing_material = [
//   { material: { name: "box 112x444", _id: "id1" }, quantity: 1 },
//   { material: { name: "paper fill", _id: "id2" }, quantity: 12 },
// ];

const Material = ({ id, packing_material = [], setUpdate, updated }) => {
  const [serviceType, setServicetype] = useState();
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = ({ value, item }) => {
    updateProductPackingMaterial({ item, id, quantity: value }).then((resp) =>
      console.log(resp)
    );
  };

  const handleAddMaterial = () => {
    addPackingmaterial({ id, item: serviceType, quantity }).then((resp) =>
      setUpdate(!updated)
    );
  };

  const handleDeleteItem = (item) => {
    deletePackingmaterial({ item, id }).then((resp) => setUpdate(!updated));
  };

  const materialList = packing_material.map((item) => (
    <Table.Row>
      <Table.Cell>{item.material.name}</Table.Cell>
      <Table.Cell>
        {" "}
        <EditableText
          style={{ minWidth: "80px", maxWidth: "200px" }}
          placeholder={"quantity"}
          value={item.quantity}
          name={"quantity"}
          handleChange={(e, { value }) =>
            handleQuantityChange({ value, item: item.material._id })
          }
          transparentBackground={true}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          icon="delete"
          size='tiny'
          onClick={() => handleDeleteItem(item.material._id)}
        ></Button>
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <div>
      <Header>Packing Material</Header>
      <div className="flex--left">
        <ServiceTypes
          fluid={true}
          handleChange={(value) => {
            setServicetype(value);
          }}
          style={{ maxWidth: "300px" }}
          value={serviceType}
          multiple={false}
          category={`pack_material`}
        />
        <Input
          value={quantity}
          onChange={(e, { value }) => setQuantity(value)}
        ></Input>
        <Button
          onClick={() =>
            handleAddMaterial("add new material:", serviceType, quantity)
          }
          style={{ marginLeft: "10px" }}
        >
          Add material
        </Button>
      </div>
      {packing_material.length > 0 && (
        <Table style={{ maxWidth: "400px" }}>
          <Table.Header>
            <Table.Cell width={3}></Table.Cell>
            <Table.Cell width={1}>Quantity</Table.Cell>
            <Table.Cell width={1}>Action</Table.Cell>
          </Table.Header>
          {materialList}
        </Table>
      )}
    </div>
  );
};

export default Material;
