import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";

import ExportExcel from "../ExportExcel";

function ExportButton(props) {
  const { filename, data, style = {} } = props;

  const [dataSet, setData] = useState();

  const colNames = [
    { label: "error_row", value: "error_row" },
    { label: "error_cln", value: "error_cln" },
    { label: "value", value: "value" },
  ];

  // useEffect(() => {
  //   const received = data.map((item) => ({
  //     label: key_name,
  //     id: item,
  //   }));
  //   setData(received);
  // }, [data]);

  return (
    <ExportExcel
      filename={filename}
      excelSheetName="list"
      dataSet={data}
      colNames={colNames}
      button={
        <Button
          style={style}
          basic
          color="grey"
          disabled={!data || !data.length}
        >
          <Icon name="excel file" />
          Export {`${data && data.length}`}
        </Button>
      }
    />
  );
}
export default ExportButton;
