import React, { useState } from "react";
import { Input, Button } from "semantic-ui-react";

function Item({ product, courier, handleStartPicking }) {
  const [limit, setLimit] = useState(product.orders.length);
  // console.log("ITEM product",product)
  return (
    <tr
      key={product.products_name}
      style={{ border: "1px solid red", cursor: "move" }}
    >
      <td>{product.products_name}</td>
      <td className="text-center">{product.quantity}</td>
      <td className="text-center">{product.orders.length}</td>
      <td>
        <Input
          style={{maxWidth:'100px'}}
          type="number"
          min="0"
          max={product.orders.length}
          fluid
          id={product.products_name}
          name={courier}
          onChange={(e, { value }) => setLimit(value)}
          defaultValue={product.orders.length}
        />
      </td>
      <td >
        <Button
          onClick={() => handleStartPicking({ orders: product.orders, limit })}
        >
          Start Picking
        </Button>
      </td>
    </tr>
  );
}

export default Item;
