import React, { useState, useEffect, useRef } from "react";
import {
  Segment,
  Header,
  Divider,
  Loader,
  Dimmer,
  Message,
  Button,
} from "semantic-ui-react";
// import { socket } from "../../../containers/Main";
import html2pdf from "html2pdf.js";
import qrCode from "./qr_link.jpeg";

import Barcode from "react-barcode";
// import { apiCall } from "../../../services/api";
// import axios from "axios";
import {
  getNextOrder,
  orderAction,
  getPackerOrder,
  printJob,
  getDocumentsToPrint,
} from "../../../actions/packing";
import { getAwb } from "../../../actions/orders";
import CanViewPage from "../../../role-based-access/CanViewPage";
import ProductsList from "./ProductsList";
import Prints from "./Prints";
import ActionsPrimary from "./ActionsPrimary";
import ActionsSecondary from "./ActionsSecondary";
import TopBar from "./TopBar";
import "./packer.scss";
import { Alert } from "rsuite";

const getTriggerIds = (items) => {
  return items.map((item) => item.product);
};
const initState = {
  docs: [],
  // isBlocking: true,
  // isPacked: false,
  processingInvoice: false,
  barcodePrintedOnload: false,
  printLocal: false,
};
const Packerview = (props) => {
  const [state, setState] = useState(initState);
  const [order, setOrder] = useState({
    clientOrderNr: "",
    payment: { COD: { amount: 0 } },
    processing: {
      instrutions: [],
    },
    errMsgs: [],
    _id: null,
    items: [],
    shipping: {
      shipTo: { client: { firstName: "", lastName: "" } },
      courier: {
        _id: "",
        settings: { sticker_pdf_local: false, printBarcodeOrder: false },
      },
    },
    printInvoice: false,
  });
  const [isLoading, setIsloading] = useState(false);
  const [hitPack, setHitPack] = useState(false);

  const orderRef = useRef(order);

  const setOrderState = (data) => {
    orderRef.current = data.foundOrder;
    setOrder({ ...data.foundOrder, printInvoice: data.printInvoice });
  };

  const hitPackRef = useRef(hitPack);

  const setHitPackRef = (data) => {
    hitPackRef.current = data;
    setHitPack(data);
  };

  const handleDocuemntsToPrint = () => {
    // console.log('printing documents:',orderRef.current)
    const triggerIds = getTriggerIds(orderRef.current.items);

    const id = props.match.params.id;
    const country = orderRef.current.shipping.shipTo.country;

    //Add invoice url if available invoice
    getDocumentsToPrint({ id, triggerIds, country })
      .then((docs) => {
        for (let i = 0; i < docs.length; i++) {
          const file = docs[i];
          // console.log("Printing file:",file)
          for (let j = 0; j < file.urls.length; j++) {
            printJob({ format: file.format, dataUrl: file.urls[j] }).catch(
              (error) => Alert.error(error)
            );
          }
        }
        setState({ ...state, docs });
      })
      .catch((err) => Alert.error(JSON.stringify(err)));
  };

  const handleBarcodePrintFromBtn = () => {
    setState({ ...state, isBarcodePrinted: true });
    handleBarcodePrint(order._id);
  };

  const handleAWBPrint = () => {
    if (orderRef.current.shipping.tracking_awbPdf) {
      printJob({
        format: orderRef.current.shipping.tracking_awbPdf_printFromat,
        dataUrl: orderRef.current.shipping.tracking_awbPdf,
        name:'label_pdf'
      }).catch((error) => Alert.error(error));
    } else if (orderRef.current.shipping.label_data)
      printJob({
        format: orderRef.current.shipping.tracking_awbPdf_printFromat,
        name:'label_data',
        order: {
          shipping: { label_data: orderRef.current.shipping.label_data },
        },
      }).catch((error) => Alert.error(error));
    else if (orderRef.current.shipping.zpl)
      printJob({
        format: orderRef.current.shipping.tracking_awbPdf_printFromat,
        name:'label_zpl',
        order: { shipping: { zpl: orderRef.current.shipping.zpl } },
      }).catch((error) => Alert.error(error));
    else {
      Alert.error("No label available in PDF format.");
    }
  };

  const handleBarcodeView = (order) => {
    const opt = {
      filename: `barcode-${order.clientOrderNr}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        unit: "mm",
        format: "a6",
        // format: [59 ,165],
        // orientation: "l",
        orientation: "p",
      },
    };
    const barcode = document.querySelector(".barcode-container-label");
    html2pdf().set(opt).from(barcode).toPdf().save();
  };

  const handleBarcodePrint = (clientOrderNr) => {
    // alert('barcode')
    const opt = {
      filename: `barcode-${clientOrderNr}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        unit: "mm",
        // format: [59 ,165],
        format: "a6",
        orientation: "p",
        // orientation: "l",
      },
    };

    const barcode = document.querySelector(".barcode-container-label");
    html2pdf()
      .set(opt)
      .from(barcode)
      .outputPdf("dataurlstring")
      .then((data) => {
        printJob({
          format: "barcode",
          uriData: data,
        }).catch((error) => Alert.error(error));
      });
  };

  const handleInvoicePrint = () => {
    if (orderRef.current.invoice) {
      const url = `http://api.internal.wyzbox.com/api/external/invoices/${orderRef.current.invoice._id}/pdf`;
      // setState({...state,docs:[{format:'A4',urls:[url]}]})
      printJob({ format: "A4", name:'invoice',dataUrl: url }).catch((error) =>
        Alert.error(error)
      );
    }
  };

  const handlePrintUrls = () => {
    printJob({
      dataUrl: "test",
      format: "A4",
      order: {},
    }).catch((error) => Alert.error(error));
    // if(order.print.length>0){
    //   for(let i = 0;i<order.print.length;i++){

    //     const url = order.print[i].url

    //   }
    // }
  };

  const hadndleGetNextOrder = () => {
    setIsloading(true);
    setHitPackRef(false);

    getNextOrder()
      .then((resp) => {
        setIsloading(false);
        if (resp.foundOrder._id) {
          props.history.push(`/packer/orders/${resp.foundOrder._id}`);
          // setOrderState(resp)
        } else {
          props.history.push(`/packer/orders`);
          Alert.success("No orders.");
        }
      })
      .catch((err) => {
        setIsloading(false);
        Alert.error(err.message);
      });
  };

  const handleOrderAction = (action) => {
    // console.log('hitpack is:',hitPackRef.current)

    setIsloading(true);
    setHitPackRef(true);

    if (action === "pack") order.status = "Packed";

    orderAction({ action, orderId: orderRef.current._id })
      .then((resp) => {
        setIsloading(false);
        setOrderState(resp);

        // console.log('in order action hitPacked:',hitPackRef.current)
      })
      .catch((err) => {
        setIsloading(false);
        Alert.error(err.message);
      });
  };

  const handleGetAwb = (order) => {
    getAwb(order._id)
      .then((resp) => {
        console.log("RESPONSE:", resp);
        if (resp.status === "success") {
          setOrder({
            ...order,
            shipping: { ...order.shipping, trackingId: resp.trackingId },
          });

          if (resp.dataUrl)
            printJob({
              format: resp.format,
              dataUrl: resp.dataUrl,
            }).catch((error) => Alert.error(error));
        }
        handleDocuemntsToPrint(resp.foundOrder);
        // if(order.shipping.courier.settings.printBarcodeOrder) handleBarcodePrint(resp.foundOrder._id);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setState(initState);
    setIsloading(true);
    getPackerOrder(props.match.params.id)
      .then((resp) => {
        setOrderState(resp);
        setIsloading(false);

        if (
          !resp.foundOrder.shipping.trackingId &&
          !resp.foundOrder.shipping.courier.settings.sticker_pdf_local
        )
          handleGetAwb(resp.foundOrder);

        if (resp.foundOrder.print.length > 0)
          printJob({
            order: resp.foundOrder,
          }).catch((error) => Alert.error(error));

        handleAWBPrint();

        if (resp.foundOrder.printInvoice) handleInvoicePrint();

        if (resp.foundOrder.shipping.courier.settings.printBarcodeOrder)
          handleBarcodePrint(resp.foundOrder._id);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });

    // setState({ ...state,isBarcodePrinted: props.isBarcodePrinted });
  }, [props.match.params.id]);

  const docsToPrint = state.docs.map((item) => {
    return (
      <tr>
        <td>{item.name}</td>
        <td>
          <button
            onClinck={() =>
              printJob({ format: item.format, dataUrl: item.urls[0] }).catch(
                (error) => Alert.error(error)
              )
            }
          >
            print
          </button>
        </td>
      </tr>
    );
  });

  if (isLoading)
    return (
      <Dimmer active inverted>
        <Loader inverted size="medium">
          Loading
        </Loader>
      </Dimmer>
    );

  return (
    <CanViewPage path="/packer/orders/:id">
      {/* {
        Object.keys(order).length ? ( */}
      <main>
        {/* TOP BAR */}
        <section className="top-bar">
          <Segment>
            <TopBar openOrder={order} />
          </Segment>
          {/* <Header as="h3">Packer</Header>
            <Divider /> */}
        </section>
        {order.errMsgs.length > 0 &&
          order.errMsgs.map((err, i) => (
            <div className="alert alert-danger a-wrapper" key={i}>
              {err}
            </div>
          ))}

        {/* INSTRUCTION! */}
        {order.processing.instructions && (
          <div className="a-wrapper">
            <Message error size="huge">
              <Message.Header>Packing Instructions!</Message.Header>
              <p>{order.processing.instructions}</p>
            </Message>
          </div>
        )}
        {/* PACKING INFORMATION */}
        <section className="a-wrapper packing-info flex--between">
          {/* <div className="packing-info__order">
              <Segment>
                <TopBar openOrder={openOrder} openorder._id={openorder._id} />
              </Segment>
            </div> */}
          <div className="packing-info__products">
            <div className="flex--between">
              <h3>
                Total COD:
                {order.payment.COD.amount}
              </h3>
              <h3>Total Quantity: {order.processing.total_quantity}</h3>
            </div>

            <Segment>
              <div className="products-section__wrapper">
                <Header as="h4">Products</Header>
                <Divider />
                <ProductsList items={order.items} />
              </div>
            </Segment>
          </div>

          {/* <span className="vertical-divider" /> */}

          <div className="packing-info__sidebar">
            <Segment>
              <div className="sidebar-container">
                <div className="packing-info__print">
                  <div className="flex--between vertical--center">
                    <Header className="print__header" as="h4">
                      To Print
                    </Header>
                    {/* <Button
                      size="small"
                      color={state.isPrinted ? "" : "grey"}
                      onClick={handlePrint}
                    >
                      <Icon name="print" />
                      {state.isPrinted ? "Printed" : "Print!"}
                    </Button> */}
                  </div>
                  <Divider />
                  <Prints
                    handleAWBPrint={handleAWBPrint}
                    handleBarcodePrint={handleBarcodePrint}
                    handleInvoicePrint={handleInvoicePrint}
                    awbDatalink={order.shipping.tracking_awbPdf}
                  />
                </div>

                <div className="packing-info__pack-materials">
                  <Header as="h4">Printing files</Header>
                  <Divider />

                  <table>{docsToPrint}</table>

                  <Button onClick={() => handleDocuemntsToPrint()}>
                    Print ALL
                  </Button>
                  {/* <PackingMaterials /> */}
                </div>
              </div>
            </Segment>
          </div>
        </section>

        {/* ACTIONS */}
        <section className="actions  a-wrapper">
          <ActionsPrimary
            handleBarcodePrintFromBtn={handleBarcodePrintFromBtn}
            isBarcodePrinted={props.isBarcodePrinted}
            hitPack={hitPackRef.current}
            hadndleGetNextOrder={hadndleGetNextOrder}
            handlePackedClick={() => {
              handleOrderAction("pack");
            }}
          />

          <ActionsSecondary handleOrderAction={handleOrderAction} />
        </section>

        <div
          style={{
            // display: "none",
            width: "100%",
            // marginLeft: "20px",
          }}
        >
          <div
            className="barcode-container-label"
            style={{
              height: "500px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="flex--between">
              <Barcode
                value={order.internalNumericId}
                // A4
                // height={340}
                // width={5}
                // fontSize={40}
                // margin={120}

                // A6
                height={80}
                width={1.8}
                fontSize={15}
                margin={20}
                // barcode label size
                // height={40}
                // width={1}
                // fontSize={12}
                // marginTop={3}
                className="barcode"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "between",
                  margin: "20px",
                }}
              >
                <div
                  style={{
                    margin: "10px",
                    alignSelf: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  WWW.WYZBOX.COM
                </div>
                <div
                  style={{
                    alignSelf: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  DO NOT COVER!
                </div>
                <div
                  style={{
                    alignSelf: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  NIE ZAKLEJAĆ KODU!
                </div>
              </div>
            </div>
            <img
              src={qrCode}
              alt="QR CODE"
              width="190"
              height="190"
              style={{ alignSelf: "center" }}
            ></img>
          </div>
        </div>

        {/* FRONT PDFS TO PRINT
        <div style={{ margin: "10px" }}>
          {!["Packed", "Draft", "PackingError"].includes(order.status) &&
            (order.shipping.courier._id === process.env.REACT_APP_PPL_CZ_ID ||
              order.shipping.courier._id ===
                process.env.REACT_APP_PPL_SK_ID) && (
              <PPLetiket
                // hanldeLocalPrintDone={hanldeLocalPrintDone}
                order={order}
                printJob={props.printJob}
                // trackingId = {order.shipping.trackingId}
                // printLocal={state.printLocal}
              ></PPLetiket>
            )}
        </div> */}
      </main>
    </CanViewPage>
  );
};

export default Packerview;
