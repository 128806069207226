import React, { useEffect, useState } from "react";
import { Segment, Header, Table } from "semantic-ui-react";
import { getStockBalance_per_inventory } from "../../../actions/product";
import Reveal from "../../../components/reveal";
import { Link } from "react-router-dom";
// import Moment from "react-moment";
import EditableText from "../../EditableText";
import { updateInventoryCard } from "../../../actions/inventory";
import { DatePicker } from "rsuite";

const Stockavailable = ({ id }) => {
  const [stockTotals, setStockTotals] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateInv = ({ id, body }) => {
    updateInventoryCard({ id, body })
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setLoading(true);
    getStockBalance_per_inventory(id)
      .then((resp) => {
        setLoading(false);
        setStockTotals(resp);
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
      });
  }, []);

  const products = stockTotals.map((warehouse) => (
    <div>
      <Reveal
        header={<div>{warehouse.name} </div>}
        subHeader={`Stocks in warehouse-${warehouse.total}`}
        body={
          <Table>
            <Table.Header>
              <th>#</th>
              <th>Trigger</th>
              <th>LOT</th>
              <th>Expiration</th>
              <th>Quantity</th>
            </Table.Header>
            <Table.Body>
              {warehouse.inventories.map((inv, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      {inv.triggredBy}
                      <br></br>
                      {inv.triggredBy === "Inboundshipment" && (
                        <span className="smallinfo">
                          <Link
                            target="_blank"
                            to={`/shipments/inbound/${
                              inv.triggerId && inv.triggerId
                                ? inv.triggerId
                                : "null"
                            }`}
                          >
                            {inv.triggerId && inv.triggerId
                              ? inv.triggerId
                              : "null"}
                          </Link>
                        </span>
                      )}
                      {inv.triggredBy === "Inboundparcel" && (
                        <span className="smallinfo">
                          <Link
                            target="_blank"
                            to={`/parcels/inbound/${
                              inv.triggerId && inv.triggerId
                                ? inv.triggerId
                                : "null"
                            }`}
                          >
                            {inv.triggerId && inv.triggerId
                              ? inv.triggerId
                              : "null"}
                          </Link>
                        </span>
                      )}
                    </td>
                    <td>
                      <EditableText
                        style={{ width: "200px" }}
                        placeholder={"lot..."}
                        value={inv.lot}
                        name={"lot"}
                        handleChange={(e, { value }) =>
                          updateInv({ id: inv._id, body: { lot: value } })
                        }
                        transparentBackground={true}
                      />
                    </td>
                    <td>
                      {/* <Moment format="DD/MM/YYYY">{inv.expiration_date}</Moment> */}
                      <DatePicker
                        style={{ width: "200px" }}
                        oneTap
                        // placeholder="select"
                        value={inv.expiration_date}
                        format="DD-MM-YYYY"
                        // ranges={[dateFrom,dateTo]}
                        onChange={(value) =>
                          updateInv({
                            body: { expiration: value },
                            id: inv._id,
                          })
                        }
                      />
                    </td>
                    <td>{inv.quantity}</td>
                  </tr>
                );
              })}
            </Table.Body>
          </Table>
        }
      />

      {/* <tr>
      <td>{item.warehouse}</td>
      <td>{item.total}</td>
    </tr> */}
    </div>
  ));

  return (
    <div className="card_">
      <Header >Stock Balances</Header>
      <Segment loading={loading}>{products}</Segment>
    </div>
  );
};

export default Stockavailable;
