import React, { useState, useEffect } from "react";
import { Divider } from "rsuite";
import { Card } from "semantic-ui-react";
import { deliveryrateReport } from "../../actions/reports";
import Moment from "react-moment";

const Deliveryratecards = ({
  isMobile,
  dateFrom,
  dateTo,
  courier,
  product,
  businessclient,
  warehouse,
}) => {
  const [deliveryratelist, setDeliveryratelist] = useState({report:[],fromDate:'',toDate:''});

  const [viewMore, setViewMore] = useState(true);

  useEffect(() => {
    deliveryrateReport({
      dateFrom,
      dateTo,
      courier,
      product,
      businessclient,
      warehouse,
    })
      .then((resp) => {
        setDeliveryratelist(resp)
      })
      .catch((err) => console.log(err));
  }, [dateFrom, dateTo, courier, product, businessclient, warehouse]);

  let items = [];

  for (let j = 0; j < deliveryratelist.report.length; j++) {
    const item = deliveryratelist.report[j];

    let lines = [];

    for (let i = 0; i < item.statuses.length; i++) {
      const status = item.statuses[i];

      const newLine = (
        <tr>
          <th style={{ textAlign: "left" }}>
            {status.group} ({status.groupTotal})
          </th>
          <th style={{ width: "40px" }}>{Math.round(status.percentage)} %</th>
        </tr>
      );

      lines = [...lines, newLine];
    }

    const newItem = (
      <Card fluid={isMobile}>
        <Card.Content>
          <Card.Header>
            {item.courier} ({item.statuses[0].courierTotal})
          </Card.Header>
          <Card.Description>
            <table>{lines}</table>
          </Card.Description>
        </Card.Content>
      </Card>
    );
    items = [...items, newItem];

    if (!viewMore && j === 2) break;
  }
  if (deliveryratelist.report.length === 0)
    return <div>Loading delivery rate data...</div>;
  return (
    <div>
      <div>
        Period: <Moment format="DD-MM-YY">{deliveryratelist.fromDate}</Moment> :
        <Moment format="DD-MM-YY">{deliveryratelist.toDate}</Moment>
      </div>
      <Card.Group>{items}</Card.Group>
      {deliveryratelist.length > 3 && (
        <button
          style={{ float: "right" }}
          onClick={() => setViewMore(!viewMore)}
        >
          view {!viewMore ? `more ${deliveryratelist.length - 3}` : "less"}...
        </button>
      )}
      {deliveryratelist.length > 0 && <Divider></Divider>}
    </div>
  );
};

export default Deliveryratecards;
