import React from "react";
import { Button, Tab,Header } from "semantic-ui-react";
import {Alert} from 'rsuite'

import { getNextOrder } from "../../../actions/packing";

import PackingList from "./PackingList";
import DraftList from "./DraftList";
import AssignedList from "./AssignedList";
import CanViewPage from "../../../role-based-access/CanViewPage";

class PackerList extends React.Component {
  
  render() {
  
    const panes = [
      {
        menuItem: "Assigned List",
        render: () => (
          <Tab.Pane attached={false}>
            <AssignedList />
          </Tab.Pane>
        )
      },
      {
        menuItem: "Draft List",
        render: () => (
          <Tab.Pane attached={false}>
            <DraftList />
          </Tab.Pane>
        )
      },
      {
        menuItem: "Packing List",
        render: () => (
          <Tab.Pane attached={false}>
            <PackingList {...this.props} />
          </Tab.Pane>
        )
      }
    ];

    return (
      <CanViewPage path="/packer/orders">
        <div className='page-wrapper flex--between'>
          
            <Header>Orders To Pack</Header>
            <Button
              color="teal"
              className="uppercase"
              onClick={() =>
                getNextOrder({
                  history: this.props.history,
                  orderId: "",
                }).then(resp=>{
                  if (resp.foundOrder !== "No orders.") {

                        this.props.history.push(`/packer/orders/${resp.foundOrder._id}`);
                      } else {
                                            Alert.error('No orders')
                        
                        this.props.history.push(`/packer/orders`);
                      }
      }).catch(err=>alert(err))
              }
            >
              Start Packing
            </Button>

          <main className="a-wrapper" style={{ margin: "3em" }}>
            <Tab
              menu={{
                color: "teal",
                inverted: false,
                attached: false,
                tabular: false,
              }}
              panes={panes}
            />
          </main>
        </div>
      </CanViewPage>
    );
  }
}

export default (PackerList);
