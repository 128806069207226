import React from "react";
import {
  Segment,
  Header,
  Input,
  Divider
} from "semantic-ui-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
// import DateFilter from "../../Filters/DateFilter";
import ItemsPerPage from "../../ItemsPerPage";
// import Pagination from "../../Filters/Pagination";
import ExportMovementsButton from "./ExportMovementsButton";
import WarehouseFilter from '../../Filters/WarehouseFilter'
import {DatePicker} from 'rsuite'
import{Pagination} from 'semantic-ui-react'


export const Stockmovements = ({
  loading,stockMovements,setFilters,filters
}) => {

  const { clientOrderNr, currentPage, itemsPerPage } = filters;

  const handleChange = ({ name, value }) =>
    setFilters(prev => ({ ...prev, [name]: value, currentPage: 1 }));
  const handleDateFromChange = dateFrom =>
    setFilters(prev => ({ ...prev, dateFrom, currentPage: 1 }));
  const handleDateToChange = dateTo =>
    setFilters(prev => ({ ...prev, dateTo, currentPage: 1 }));
  const handlePageChange = (e,{ activePage }) =>
    setFilters(prev => ({ ...prev, currentPage:activePage }));




  const{total,movements}=stockMovements

  const tableHeader = (
    <tr>
              <th>#</th>
              <th>Description</th>
              <th>Trigger</th>
              {/* <th>InventoryCard</th> */}
              <th>Inventory trigger</th>
              <th>Date</th>
              <th>Quantity</th>
              <th>Warehouse</th>
              <th>Quantity Available</th>
            </tr>
  );

  const tableBody = movements.map(
    (move = { triggerId: { _id: null } }, index) => (
      <tr key={index}>
        <td>{itemsPerPage * (currentPage - 1) + index + 1}</td>
        <td>{move.description}</td>
        <td>
          {move.triggredBy}
          <br></br>
          {move.triggredBy === "Order" && (
            <span className="smallinfo">
              {move.triggerId && move.triggerId._id && (
                <Link target="_blank" to={`/orders/${move.triggerId._id}`}>
                  {move.triggerId.clientOrderNr}
                </Link>
              )}
              {move.actionSource && move.actionSource._id && (
                <Link target="_blank" to={`/orders/${move.actionSource._id}`}>
                  {move.actionSource.clientOrderNr}
                </Link>
              )}
            </span>
          )}
          {move.triggredBy === "Purchaseorder" && (
            <span className="smallinfo">
              {move.triggerId && move.triggerId._id && (
                <Link target="_blank" to={`/pos/${move.triggerId._id}`}>
                  {move.triggerId._id}
                </Link>
              )}
              {move.actionSource && move.actionSource._id && (
                <Link target="_blank" to={`/pos/${move.actionSource._id}`}>
                  {move.actionSource._id}
                </Link>
              )}
            </span>
          )}
          {move.triggredBy === "Inboundshipment" && (
            <span className="smallinfo">
              <Link
                target="_blank"
                to={`/shipments/inbound/${
                  move.triggerId && move.triggerId._id
                    ? move.triggerId._id
                    : "null"
                }`}
              >
                {move.triggerId && move.triggerId._id
                  ? move.triggerId._id
                  : "null"}
              </Link>
            </span>
          )}
        </td>
        {/* <td>{move.inventory._id}</td> */}
        {move&&move.inventory&&<td>
          {move.inventory.triggredBy}

          {move.inventory.triggredBy == "Inboundshipment" && (
            <p className="smallinfo">
              <Link
                target="_blank"
                to={`/shipments/inbound/${move.inventory.triggerId}`}
              >
                {move.inventory.triggerId}
              </Link>
            </p>
          )}
          {move.inventory.triggredBy == "Inboundparcel" && (
            <p className="smallinfo">
              <Link
                target="_blank"
                to={`/parcels/inbound/${move.inventory.triggerId}`}
              >
                {move.inventory.triggerId}
              </Link>
            </p>
          )}
        </td>}
        <td>
          <Moment format="DD/MM/YY HH:mm">{move.createdAt}</Moment>
        </td>
        <td>{move.quantity}</td>
        <td>{move.warehouse.name}</td>
        <td>{move.quantity_available}</td>
      </tr>
    )
  );

  

  return (
    <div className="card_">
      <Segment loading={loading}>
        <div className="stockmovement-top">
          <Header as="h4">StockMovements</Header>
          <Divider />
          <div className="flex--between">
            <Input
              style={{ height: "35px" }}
              className="stockmovement-filters__description"
              type="text"
              name="clientOrderNr"
              value={clientOrderNr}
              placeholder="clientOrderNr..."
              onChange={(e,{value})=>handleChange({name:"clientOrderNr",value})}
            />
            <div>
              <DatePicker
                value={filters.dateFrom}
                isClearable={true}
                style={{ width: "200px" }}
                placement="topStart"
                format="DD-MM-YYYY"
                onChange={(date) => handleDateFromChange(date)}
                placeholder="date from"
                oneTap
              ></DatePicker>

              <DatePicker
                oneTap
                value={filters.dateTo}
                style={{ width: "200px" }}
                isClearable={true}
                placement="topStart"
                format="DD-MM-YYYY"
                onChange={(date) => handleDateToChange(date)}
                placeholder="date to"
              ></DatePicker>
            </div>
            <WarehouseFilter
              useFilter={false}
              style={{ height: "35px" }}
              name="warehouse"
              handleChange={(value)=>handleChange({name:"warehouse",value})}
            ></WarehouseFilter>

            <ItemsPerPage
              style={{ marginBottom: "0.5em" }}
              value={itemsPerPage}
              setItemsPerPage={(value)=>handleChange({name:"itemsPerPage",value})}
              total={total}
            />
          </div>
          <div>
            <ExportMovementsButton items={movements} />
          </div>
        </div>
        {/* <Divider /> */}
        <table
          // fixed
          className="hoverTable dataTable"
          // style = {{width:"100%"}}
        >
          <thead>{tableHeader}</thead>
          <tbody>{tableBody}</tbody>
        </table>

    
         <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(total / itemsPerPage)}
              boundaryRange={1}
              siblingRange={1}
              firstItem={null}
              lastItem={null}
              onPageChange={handlePageChange}
            />
      </Segment>
    </div>
  );
};
