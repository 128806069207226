import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Segment,
  Input,
  Table,
  Button,
  Divider,
} from "semantic-ui-react";
import {
  createTemplate,
  getTemplate,
} from "../../actions/courier_cost_teamplates";
import { Alert } from "rsuite";

import CourierFilter from "../Filters/CourierFilter";

const look_up_key_options = [
  { text: "Internal Id", value: "internalNumericId" },
  { text: "Tracking Id", value: "shipping.trackingId" },
  { text: "Client Order Number", value: "clientOrderNr" },
];

const Newteamplate = ({ template, setTemplate,courier,setCourier }) => {
  const [templates, setTemplates] = useState([]);
  const [clickedIndex, setIndex] = useState(0);
  const [addNew, setAddNew] = useState(false);

  const handleCreateTemplate = () => {
    setAddNew(false);
    // console.log({...template,courier})
    createTemplate({ ...template, courier })
      .then((resp) => setTemplate(resp))
      .catch((err) => Alert.error(err));
  };

  useEffect(() => {
    //Load templates for this courier
    if (courier)
      getTemplate({ courier }).then((resp) => {
        setTemplates(resp);
        if (resp.length > 0) setTemplate(resp[0]);
      });
    if (courier === "") {
      setTemplates([]);
      setAddNew(false);
    }
  }, [courier]);

  const teamplateComponent = templates.map((item, index) => (
    <Button
      style={{ margin: "5px" }}
      active={index === clickedIndex ? true : false}
      basic
      onClick={() => {
        setTemplate(item);
        setIndex(index);
      }}
    >
      <Table>
        <tr>
          <td>Look up key</td>
          <td>{item.look_up_key}</td>
        </tr>
        <tr>
          <td>Look up columns</td>
          <td>{item.look_up_key_clns.map(item=>(`${item},`))}</td>
        </tr>
        <tr>
          <td>Amount Cost Columns</td>
          <td>{item.cost_amount_clns.map(item=>(`${item},`))}</td>
        </tr>
        <tr>
          <td>Cost Description Position</td>
          <td>{item.cost_desc_position}</td>
        </tr>
        <tr>
          <td>Description Column</td>
          <td>{item.description_cln}</td>
        </tr>
      </Table>
    </Button>
  ));

  const newTemplateForm = (
    <div>
      <Table
        style={{
          width: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {" "}
        <tr>
          <Table.Cell disabled={!courier}>Title Row</Table.Cell>
          <Table.Cell disabled={!courier}>
            <Input
              min={1}
              disabled={!courier}
              defaultValue={1}
              onChange={(e, { value }) => {
                if (value > 0) setTemplate({ ...template, title_row: value });
              }}
            ></Input>
          </Table.Cell>
        </tr>
        <tr>
          <Table.Cell disabled={!courier}>Look up key</Table.Cell>
          <Table.Cell disabled={!courier}>
            {" "}
            <Dropdown
              disabled={!courier}
              placeholder="State"
              search
              selection
              defaultValue={`shipping.trackingId`}
              options={look_up_key_options}
              onChange={(e, { value }) =>
                setTemplate({ ...template, look_up_key: value })
              }
            ></Dropdown>
          </Table.Cell>
        </tr>
        <tr>
          <Table.Cell disabled={!courier}>Key Columns</Table.Cell>
          <Table.Cell disabled={!courier}>
            {" "}
            <Input
              placeholder={"1,2,3..."}
              disabled={!courier}
              // defaultValue={1}
              value={template.look_up_key_clns}
              onChange={(e, { value }) => {
                console.log(
                  "template.look_up_key_clns:",
                  template.look_up_key_clns
                );
                if (value != "")
                  setTemplate({
                    ...template,
                    look_up_key_clns: `${value}`.split(","),
                  });
                else setTemplate({ ...template, look_up_key_clns: [] });
              }}
            ></Input>
          </Table.Cell>
        </tr>
        <tr>
          <Table.Cell disabled={!courier}>Cost Description Position</Table.Cell>
          <Table.Cell disabled={!courier}>
            {" "}
            <Dropdown
              disabled={!courier}
              search
              selection
              defaultValue={template.cost_desc_position}
              options={[
                { text: "In Columns", value: "in_cln_title" },
                { text: "In Row", value: "in_row" },
              ]}
              onChange={(e, { value }) =>
                setTemplate({ ...template, cost_desc_position: value })
              }
            ></Dropdown>
          </Table.Cell>
        </tr>
        {template.cost_desc_position==='in_row'&&<tr>
          <Table.Cell disabled={!courier}>Cost Description Column</Table.Cell>
          <Table.Cell>
            <Input
              disabled={!courier}
              // defaultValue={1}
              value={template.description_cln}
              onChange={(e, { value }) => {
                setTemplate({ ...template, description_cln: value });
              }}
            />
          </Table.Cell>
        </tr>}
        <tr>
          <Table.Cell disabled={!courier}>Cost Amount Columns</Table.Cell>
          <Table.Cell>
            {" "}
            <Input
              placeholder={"1,2,3..."}
              disabled={!courier}
              // defaultValue={1}
              value={template.cost_amount_clns}
              onChange={(e, { value }) => {
                console.log(
                  "template.cost_amount_clns:",
                  template.cost_amount_clns
                );
                if (value != "")
                  setTemplate({
                    ...template,
                    cost_amount_clns: `${value}`.split(","),
                  });
                else setTemplate({ ...template, cost_amount_clns: [] });
              }}
            ></Input>
          </Table.Cell>
        </tr>
      </Table>
      <Button
        color="green"
        style={{ width: "500px" }}
        onClick={() => handleCreateTemplate()}
      >
        Create Template
      </Button>
    </div>
  );

  return (
    <Segment>
      <h4>Courier Template</h4>
      <CourierFilter
        style={{ width: "500px" }}
        multiple={false}
        useFilter={false}
        handleChange={(value) => setCourier(value)}
        value={courier}
      ></CourierFilter>

      {templates.length > 0 && (
        <Button style={{ marginLeft: "20px" }} onClick={() => setAddNew(true)}>
          Add New
        </Button>
      )}
      {courier && <Divider></Divider>}

      {!addNew && teamplateComponent}
      {/* {templates.length === 0&& (
        <div
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        >
          <PlaceholderExampleImage></PlaceholderExampleImage>
        </div>
      )} */}

      {addNew && newTemplateForm}

      {templates.length === 0 && courier && (
        <div>
          <p>No template found. Create new teamplate.</p>
          {newTemplateForm}
        </div>
      )}
    </Segment>
  );
};

export default Newteamplate;
