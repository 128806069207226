import React from "react";
// import { TieredMenu } from "primereact/tieredmenu";
import { Menu, Dropdown, } from "semantic-ui-react";

import { useExcelOrderData } from "./useExcelOrderData";

// import "primereact/resources/themes/nova-light/theme.css";
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";

function OrdersListMenu(props) {
  const {
    checkedItems = [],
    orders,
    openChangeStatusModal,
    openChangeStatusModalFromInput,
    openShipFromWarehouseModal,
    openAssignUserModal,
    toggleAssignCourierModal,
    manualAssing,
  } = props;

  const { createExcelFile } = 
  useExcelOrderData(
    orders,
    checkedItems,
    "orders-selected"
  );

  return (
    <div>
      <Menu
      // vertical
      >
        <Dropdown
          item
          text={`Actions  ${
            checkedItems.length > 0 ? checkedItems.length : ""
          }`}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={false}
              icon="edit"
              text="Change Status -  From Input"
              onClick={()=> openChangeStatusModalFromInput()}
            />

            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="edit"
              text={`Change Status`}
              onClick={()=>openChangeStatusModal()}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="file"
              text={`Export selection (${checkedItems.length})`}
              onClick={()=>createExcelFile()}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="user"
              text="Assign packer"
              onClick={()=>openAssignUserModal()}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="remove user"
              text="Un-Assign packer"
              onClick={()=>manualAssing({ orderIds: checkedItems, toUser: "" })}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="warehouse"
              text="Change Ship From"
              onClick={()=>openShipFromWarehouseModal()}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="shipping"
              text="Change Courier"
              onClick={()=>toggleAssignCourierModal()}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    </div>
  );
}

export default OrdersListMenu;
