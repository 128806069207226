import { apiCall } from "../services/api";

export const createTemplate = (body) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/couriercodtemplates`;
    apiCall("post", url,body) //upload file to fileserver
      .then((resp) => resolve(resp))
      .catch((err) => reject(err.message));
  });
    
};

export const getTemplate = ({courier}) => {
return new Promise((resolve, reject) => {
  const url = `${process.env.REACT_APP_API}/couriercodtemplates/${courier}`;
  apiCall("get", url) //upload file to fileserver
    .then((resp) => resolve(resp))
    .catch((err) => reject(err.message));
});
  
};
