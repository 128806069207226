import React from "react";
import Item from "./Item";
import { Header, Table } from "semantic-ui-react";

const PickerListByCourier = ({ list = [], handleStartPicking }) => {
  const items = list.map((courier) => {
    const { products } = courier;
    return (
      <div style={{margin:"10px"}}>
        <Header>{courier.courier_details}</Header>

        <Table>
          <Table.Header>
            <Table.Cell>Name</Table.Cell>
          <Table.Cell>Quantity</Table.Cell>
            <Table.Cell>#Orders</Table.Cell>
            <Table.Cell>Pick Orders</Table.Cell>
          </Table.Header>
          <Table.Body>
            {products.map((product) => (
              <Item
                handleStartPicking={handleStartPicking}
                product={product}
              ></Item>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  });

  return <div>{items}</div>;
};

export default PickerListByCourier;
